import React, { useRef } from "react";

import { Button, Card, Col, Container, Row } from "reactstrap";
import arrow from "../assets/img/rightArrow.svg";
import Slider from "react-slick";
import AllInclusive from "./AllInclusive";
import QuestionModal from "./QuestionModal";
import bluetick from "../assets/img/bluetick.svg";

import HeroSlider from "./HeroSlider";
import HeroCardSection from "./HeroCardSection";
const HeroSection = ({ version }) => {
    console.log("version: -12", version);
    const sliderRef = useRef(null);

    // const handleNext = () => {
    //     sliderRef.current.slickNext();
    // };

    // const handlePrev = () => {
    //     sliderRef.current.slickPrev();
    // };
    // const settings = {
    //     arrows: true,
    //     dots: true,
    //     fade: true,
    //     infinite: true,
    //     autoplay: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     waitForAnimate: false,
    // };
    return (
        <>
            <div className="heroSection">
                <Container className="">
                    <Row noGutters>
                        <Col
                            lg="6"
                            className={`heroSection_first d-flex flex-column justify-content-around`}
                        >
                            <div>
                                <div>
                                    <span
                                        className={` py-2 ${
                                            version === "kona-2"
                                                ? "heroSection_konaBag  pt-7"
                                                : "heroSection_bag "
                                        }  px-4  py-2`}
                                    >
                                        {version === "konazakelijk"
                                            ? "ZAKELIJKE LEASE"
                                            : version === "kona-2"
                                            ? "NU SUBSIDIE BESCHIKBAAR!"
                                            : "NIEUW"}
                                    </span>
                                </div>
                                <h1
                                    className={`text-site-primary my-2 ${
                                        (version === "kona-1" ||
                                            version === "konazakelijk" ||
                                            version === "kona-2") &&
                                        "custom_kona_title-font"
                                    }`}
                                >
                                    {version === "kona-1" ? (
                                        <>De nieuwe Hyundai KONA Electric</>
                                    ) : version === "kona-2" ? (
                                        <>De Hyundai KONA Electric</>
                                    ) : version === "konazakelijk" ? (
                                        <>
                                            De Hyundai <br /> KONA Electric
                                        </>
                                    ) : (
                                        <>De vernieuwde Hyundai i10</>
                                    )}
                                </h1>
                                <h3
                                    className={`text-site-primary my-2 ${
                                        (version === "kona-1" ||
                                            version === "konazakelijk" ||
                                            version === "kona-2") &&
                                        "custom_kona_sub_title-font"
                                    }`}
                                >
                                    {version === "kona-1" ||
                                    version === "konazakelijk" ||
                                    version === "kona-2"
                                        ? "Groter, ruimer, slimmer"
                                        : "Maak een groots statement"}
                                </h3>
                                {version === "kona-1" ||
                                version === "konazakelijk" ? (
                                    <ul
                                        className={`text mt-3 mt-md-5  ${
                                            version === "konazakelijk"
                                                ? "font-Monsterrat"
                                                : "kona_herosection_list"
                                        }`}
                                    >
                                        {/* <li>65,4 kWh batterij</li> */}
                                        <li> Direct leverbaar</li>
                                        <li>Tot wel 514 kilometer bereik</li>
                                        <li>
                                            {version === "konazakelijk"
                                                ? "Geniet van alle voordelen van Hyundai Zakelijke Lease"
                                                : "   Profiteer 48 maanden van €61 subsidie p/m"}
                                        </li>
                                    </ul>
                                ) : version === "kona-2" ? (
                                    <ul
                                        className={`text mt-3 mt-md-5  ml-2 
                                        ${
                                            version === "kona-2" ? "" : ""
                                        } font-Monsterrat list-unstyled                                      `}
                                    >
                                        <li>
                                            <img
                                                src={bluetick}
                                                alt="Blue tick"
                                                className="mr-2 p-1"
                                            />
                                            All-in maandbedrag (geen verborgen
                                            kosten)
                                        </li>
                                        <li>
                                            <img
                                                src={bluetick}
                                                alt="Blue tick"
                                                className="mr-2 p-1"
                                            />
                                            Profiteer 48 maanden van €61
                                            subsidie p/m
                                        </li>
                                        <li>
                                            <img
                                                src={bluetick}
                                                alt="Blue tick"
                                                className="mr-2 p-1"
                                            />
                                            Tot wel 514 kilometer bereik
                                        </li>
                                    </ul>
                                ) : (
                                    <p className="text mt-3 mt-md-5">
                                        Profiteer nu van een zeer scherpe
                                        aanbieding op <br /> de rijk uitgeruste
                                        i10 Comfort.
                                        <br />{" "}
                                        <b>
                                            Je stapt al in vanaf €
                                            {version === "1" ? "319" : "319"}{" "}
                                            per maand*
                                        </b>
                                    </p>
                                )}
                                {/* <ul className="text mt-3 mt-md-5 kona_herosection_list">
                                    <li>65,4 kWh batterij</li>
                                    <li>Tot wel 514 kilometer bereik</li>
                                    <li>
                                        Profiteer 48 maanden van €61 subsidie
                                        p/m
                                    </li>
                                </ul> */}
                                {/* <p className="text mt-3 mt-md-5">
                                    Profiteer nu van een zeer scherpe aanbieding
                                    op <br /> de rijk uitgeruste i10 Comfort.
                                    <br />{" "}
                                    <b>Je stapt al in vanaf €275 per maand*</b>
                                </p> */}
                            </div>
                            <div className="d-md-block d-none">
                                <HeroCardSection version={version} />
                            </div>
                        </Col>
                        <Col lg="6" className="d-lg-block d-none">
                            <HeroSlider version={version} />
                        </Col>
                    </Row>
                </Container>
                <div className=" d-lg-none d-block">
                    <HeroSlider version={version} />
                </div>
                <Container className="d-md-none d-block heroSection_first">
                    <Row>
                        <Col xs="12">
                            <HeroCardSection version={version} />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <AllInclusive />
            <QuestionModal /> */}
        </>
    );
};

export default HeroSection;
