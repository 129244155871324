import DealersRepository from "./DealersRepository";
import LeadsRepository from "./LeadsRepository";

const repositories = {
    leads: LeadsRepository,
    dealers: DealersRepository,
};

export const RepositoryFactory = {
    get: (name) => repositories[name],
};
