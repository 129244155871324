import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { steps } from "./Questions_kona-1/QuestionData";
import ContactForm from "./ContactForm";
import ScrollToTopButton from "./ScrollToTopButton";
import {
    ResetLeadsAnswers,
    addLeadsData,
} from "../store/actions/postLeadAction";
import { useDispatch } from "react-redux";

const QuestionModal_kona1 = () => {
    const dispatch = useDispatch();
    const [step, setStep] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => {
        setShowModal(false);
    };
    const handleNext = (data) => {
        setTimeout(() => {
            nextStep();
        }, 500);
    };

    const nextStep = () => {
        setStep((prevStep) => prevStep + 1);
    };
    const prevStep = () => {
        setStep((prevStep) => prevStep - 1);
    };

    useEffect(() => {
        // dispatch(ResetLeadsAnswers());
        window.scroll(0, 0);
    });
    // useEffect(() => {
    //     const queryParams = new URLSearchParams(window.location.search);
    //     const clickParam = queryParams.get("click");
    //     dispatch(
    //         addLeadsData({
    //             name: "publisher_id",
    //             value: clickParam,
    //         })
    //     );
    //     const siteParam = queryParams.get("site");
    //     dispatch(
    //         addLeadsData({
    //             name: "site_subid",
    //             value: siteParam,
    //         })
    //     );
    //     dispatch(
    //         addLeadsData({
    //             name: "site_custom_url",
    //             value: " https://hyundai.topleaseauto.nl/kona-1",
    //         })
    //     );
    //     dispatch(
    //         addLeadsData({
    //             name: "site_custom_name",
    //             value: "hoogdrempelig_hyundai_kona",
    //         })
    //     );
    // }, []);
    return (
        <>
            {step <= 3 ? (
                <Container
                    fluid
                    className="bg_img_container bg_img_container_kona"
                >
                    <Row className="justify-content-center align-items-center">
                        <Col xs="12" lg="9" md="8" xl="7" className="py-5">
                            <Card className="card_padding">
                                <CardBody className="px-0">
                                    <div>
                                        <h6 className="mb-2 font-weight-bold">
                                            {steps[step].pageNo}
                                        </h6>
                                        <h2 className="font-weight-bold-500 text-site-primary question_text">
                                            {steps[step].heading}
                                        </h2>
                                        <h5 className="">
                                            {steps[step].Sub_Heading}
                                        </h5>
                                        <h6
                                            className="mb-2"
                                            onClick={() => {
                                                setShowModal(true);
                                            }}
                                        >
                                            <u>
                                                <small className="font-weight-bold cursor-pointer">
                                                    {steps[step].title}
                                                </small>
                                            </u>
                                        </h6>
                                    </div>
                                    <div className="py-4">
                                        {React.cloneElement(
                                            steps[step]?.component,
                                            {
                                                onNext: handleNext,
                                                isOpen: showModal,
                                                handleClose: handleClose,
                                            }
                                        )}
                                    </div>
                                    <div className="px-md-4 text-left">
                                        {step !== 0 && (
                                            <button
                                                className="d-flex bg_img_container_back_btn "
                                                onClick={prevStep}
                                            >
                                                <div className="bg_img_container_back_icon mr-1">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <g clip-path="url(#clip0_26_1772)">
                                                            <path
                                                                d="M0.274969 11.3364C0.275249 11.3362 0.275484 11.3358 0.275812 11.3356L5.17444 6.46056C5.54142 6.09535 6.135 6.09671 6.5003 6.46374C6.86555 6.83073 6.86414 7.42431 6.49716 7.78956L3.20822 11.0626H23.0625C23.5803 11.0626 24 11.4823 24 12.0001C24 12.5178 23.5803 12.9375 23.0625 12.9375H3.20827L6.49711 16.2105C6.86409 16.5758 6.8655 17.1694 6.50025 17.5364C6.13495 17.9034 5.54133 17.9047 5.17439 17.5395L0.275764 12.6645C0.275484 12.6643 0.275249 12.6639 0.274921 12.6637C-0.0922508 12.2972 -0.0910778 11.7017 0.274969 11.3364Z"
                                                                fill="#002E6B"
                                                            />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_26_1772">
                                                                <rect
                                                                    width="24"
                                                                    height="24"
                                                                    fill="white"
                                                                    transform="matrix(-1 0 0 1 24 0)"
                                                                />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                Vorige
                                            </button>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div className="w-100 d-flex justify-content-end mr-5">
                        <ScrollToTopButton />
                    </div>
                </Container>
            ) : (
                <ContactForm version="kona-1" />
            )}
        </>
    );
};

export default QuestionModal_kona1;
