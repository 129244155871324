import React, { useState, useEffect } from "react";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import dealersData from "../const/dealersData.json";
import postCodes from "../const/postCodes.json";
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner,
} from "reactstrap";
import {
    addLeadsData,
    createPostLead,
    resetErrorResponse,
    setErrorResponse,
    updateLeadsData,
} from "../store/actions/postLeadAction";
import { getDealers, getLocation } from "../store/actions/dealerAction";
import { radioData_Page3 } from "./Questions/QuestionData";
function ContactForm({ version }) {
    const dispatch = useDispatch();
    const { publisher_id, site_subid, site_custom_url, site_custom_name } =
        useSelector((state) => state.leadUrlData);
    const history = useHistory();
    const [selectedDealer, setSelectedDealer] = useState("");
    const [loader, setLoader] = useState(false);
    const { loading, leads, errorResponse } = useSelector(
        (state) => state.postLead
    );
    const [qArray, setQArray] = useState([]);
    const [bootCheck, setBootCheck] = useState(false);
    const [postCodeError, setPostCodeError] = useState(false);
    const [dealersData, setDealersData] = useState([]);
    const [formDetails, setFormDetails] = useState({
        zip: "",
        gender: "",
        email: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        city: "",
        street: "",
    });

    const handleFormDetailsChange = (e) => {
        errorResponse?.[e.target.name] === true && dispatch(setErrorResponse());
        setFormDetails((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
    // const streetRegex = /^[a-zA-Z ]*$/;
    const phoneRegex = /^(0|\+?31|00\s?31)([-\s]?\d[-\s]?){7,8}\d$/;
    const emailRegex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const postalCodeRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
    const handleSubmit = async (e) => {
        e.preventDefault();

        let dealerData = await dealersData.find(
            (dealer) =>
                dealer.Dealernaam.toLowerCase() == selectedDealer.toLowerCase()
        );

        let obj = {
            ...formDetails,
            phone_number: formDetails.phone_number
                .replace(/[\s-]/g, "")
                .replace(/^00(\d+)$/, "$1"),
            zip: dealerData?.Postcode,
            city: dealerData?.City,
            street: dealerData?.Street,
        };
        console.log("obj: ", obj);
        let finalPayload = {
            ...leads,
            answers: [...leads.answers, Number(dealerData?.id)],
            ...obj,
            publisher_id: publisher_id,
            site_subid: site_subid,
            site_custom_url: site_custom_url,
            site_custom_name: site_custom_name,
        };
        dispatch(
            createPostLead(finalPayload, bootCheck, () => {
                version === "kona-1"
                    ? history.push("/kona-1/thankyou")
                    : version === "i10-2"
                    ? history.push("/i10-2/thankyou")
                    : version === "kona-2"
                    ? history.push("/kona-2/thankyou")
                    : version === "konazakelijk"
                    ? history.push("/konazakelijk/thankyou")
                    : history.push("/thankyou");
            })
        );
    };
    const nameValidation = (name) => {
        return nameRegex.test(name) || name === "";
    };

    const phoneValidation = () => {
        return (
            phoneRegex.test(formDetails?.phone_number) ||
            formDetails?.phone_number === ""
        );
    };
    const emailValidation = () => {
        return emailRegex.test(formDetails?.email) || formDetails?.email === "";
    };
    const postalCodeValidation = (value) => {
        return postalCodeRegex.test(value) || value === "";
    };
    useEffect(() => {
        version === "kona-1" ||
        version === "kona-2" ||
        version === "konazakelijk"
            ? dispatch(
                  addLeadsData({
                      name: "answers",
                      value: 4756,
                  })
              )
            : dispatch(
                  addLeadsData({
                      name: "answers",
                      value: 4659,
                  })
              );
    }, [version]);
    useEffect(() => {
        dispatch(resetErrorResponse());
    }, []);
    return (
        <>
            <Container
                fluid
                className={`bg_img_container ${
                    (version === "kona-1" ||
                        version === "kona-2" ||
                        version === "konazakelijk") &&
                    "bg_img_container_kona"
                }`}
            >
                <Row className="justify-content-center align-items-center">
                    <Col xs="12" lg="10" md="12" xl="6" className="py-5">
                        <Card className="card_padding card_custom_padding">
                            <CardBody className="contactForm_styling">
                                <h1 className=" font-weight-bold-500">
                                    Bedankt!
                                </h1>
                                {version === "kona-1" ||
                                version === "kona-2" ? (
                                    <h5 className="custom_font_size">
                                        Vul jouw gegevens in en ontvang gratis
                                        en vrijblijvend de berekening van jouw
                                        leasetarief + een superscherpe offerte
                                        op maat.{" "}
                                        <b>
                                            Let op! Deze aanbieding is slechts
                                            tijdelijk geldig.
                                        </b>
                                    </h5>
                                ) : (
                                    <h5 className="custom_font_size">
                                        Vul jouw gegevens in en ontvang gratis
                                        en vrijblijvend de berekening van jouw
                                        leasetarief. <b></b>
                                    </h5>
                                )}

                                <Form
                                    className="py-md-4"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="my-3">
                                        <FormGroup
                                            check
                                            inline
                                            className="input_bg"
                                        >
                                            <Input
                                                name="gender"
                                                id="male"
                                                type="radio"
                                                value="Meneer"
                                                checked={
                                                    formDetails.gender ===
                                                    "male"
                                                }
                                                onChange={() =>
                                                    setFormDetails({
                                                        ...formDetails,
                                                        gender: "male",
                                                    })
                                                }
                                                required
                                            />
                                            <Label
                                                check
                                                htmlFor="male"
                                                className="px-2 pt-1 text-site-primary"
                                            >
                                                Meneer
                                            </Label>
                                        </FormGroup>
                                        <FormGroup
                                            check
                                            inline
                                            className="input_bg"
                                        >
                                            <Input
                                                name="gender"
                                                id="female"
                                                type="radio"
                                                value="Mevrouw"
                                                checked={
                                                    formDetails.gender ===
                                                    "female"
                                                }
                                                onChange={() =>
                                                    setFormDetails({
                                                        ...formDetails,
                                                        gender: "female",
                                                    })
                                                }
                                                required
                                            />
                                            <Label
                                                check
                                                htmlFor="female"
                                                className="px-2 pt-1 text-site-primary"
                                            >
                                                Mevrouw
                                            </Label>
                                        </FormGroup>
                                    </div>
                                    <FormGroup className="mb-4">
                                        <Input
                                            name="firstname"
                                            placeholder="Voornaam"
                                            type="text"
                                            className="custom_form_border"
                                            bsSize="lg"
                                            required
                                            value={formDetails?.firstname}
                                            onChange={handleFormDetailsChange}
                                        />
                                        {(!nameValidation(
                                            formDetails?.firstname
                                        ) ||
                                            errorResponse?.firstname) && (
                                            <small className="text-danger mt-2">
                                                Voornaam is niet correct
                                            </small>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Input
                                            name="lastname"
                                            placeholder="Achternaam"
                                            type="text"
                                            className="custom_form_border"
                                            bsSize="lg"
                                            required
                                            value={formDetails?.lastname}
                                            onChange={handleFormDetailsChange}
                                        />
                                        {(!nameValidation(
                                            formDetails?.lastname
                                        ) ||
                                            errorResponse?.lastname) && (
                                            <small className="text-danger mt-2">
                                                Achternaam niet correct
                                            </small>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Input
                                            name="email"
                                            placeholder="E-mailadres"
                                            className="custom_form_border"
                                            bsSize="lg"
                                            required
                                            value={formDetails?.email}
                                            onChange={handleFormDetailsChange}
                                        />
                                        {(!emailValidation(
                                            formDetails?.email
                                        ) ||
                                            errorResponse?.email) && (
                                            <small className="text-danger mt-2">
                                                E-mailadres niet correct
                                            </small>
                                        )}
                                    </FormGroup>
                                    <FormGroup className="mb-4">
                                        <Input
                                            name="phone_number"
                                            placeholder="Telefoonnummer"
                                            type="text"
                                            className="custom_form_border"
                                            bsSize="lg"
                                            required
                                            value={formDetails?.phone_number}
                                            onChange={handleFormDetailsChange}
                                        />
                                        {(!phoneValidation(
                                            formDetails?.phone_number
                                        ) ||
                                            errorResponse?.phone_number) && (
                                            <small className="text-danger mt-2">
                                                Telefoonnummer niet correct
                                            </small>
                                        )}
                                    </FormGroup>

                                    <hr className="my-5" />

                                    <div className="my-3">
                                        <h5>
                                            Vul je postcode in en{" "}
                                            <b>
                                                kies een dealer bij jou in de
                                                buurt:
                                            </b>
                                        </h5>
                                    </div>

                                    {/* <div className="mb-4">
                                        <Select
                                            options={postCodeData}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null,
                                            }}
                                        />
                                    </div> */}

                                    {/* <div className="autocomplete-wrapper mb-4">
                                        <Autocomplete
                                            value={value}
                                            items={postCodes}
                                            getItemValue={(item) =>
                                                item.Dealername
                                            }
                                            renderMenu={(item) => (
                                                <div className="dropdown">
                                                    {item}
                                                </div>
                                            )}
                                            renderItem={(
                                                item,
                                                isHighlighted
                                            ) => (
                                                <div
                                                    className={`item ${
                                                        isHighlighted
                                                            ? "selected-item"
                                                            : ""
                                                    }`}
                                                >
                                                    {item.Dealername}
                                                </div>
                                            )}
                                            onChange={(event, val) =>
                                                setValue(val)
                                            }
                                            onSelect={(val) => setValue(val)}
                                        />
                                    </div> */}

                                    {/* <FormGroup className="mb-4">
                                        <Input
                                            name="zipCode"
                                            placeholder="Postcode"
                                            type="number"
                                            value={leads.zip}
                                            className="custom_form_border"
                                            onChange={(e) => {
                                                const data = {
                                                    name: "zip",
                                                    value: e.target.value,
                                                };
                                                dispatch(addLeadsData(data));
                                            }}
                                            bsSize="lg"
                                            value={formDetails?.zipCode}
                                            onChange={handleFormDetailsChange}
                                        />
                                    </FormGroup> */}

                                    {postCodes && postCodes.length > 0 && (
                                        <div className="mb-4">
                                            <Input
                                                name="zip"
                                                placeholder="Postcode"
                                                type="text"
                                                className="custom_form_border"
                                                bsSize="lg"
                                                disabled={loader}
                                                required
                                                value={formDetails?.zip}
                                                onChange={(e) => {
                                                    handleFormDetailsChange(e);
                                                    e.target.value !== "" &&
                                                        postalCodeValidation(
                                                            e.target.value
                                                        ) &&
                                                        dispatch(
                                                            getLocation(
                                                                e.target.value,
                                                                (data) => {
                                                                    if (data) {
                                                                        setPostCodeError(
                                                                            false
                                                                        );
                                                                        dispatch(
                                                                            getDealers(
                                                                                {
                                                                                    latitude:
                                                                                        data?.lat,
                                                                                    longitude:
                                                                                        data?.lng,
                                                                                },
                                                                                (
                                                                                    data
                                                                                ) => {
                                                                                    setDealersData(
                                                                                        data
                                                                                            .data
                                                                                            .data
                                                                                    );
                                                                                    setLoader(
                                                                                        false
                                                                                    );
                                                                                },
                                                                                (
                                                                                    data
                                                                                ) => {
                                                                                    setLoader(
                                                                                        data
                                                                                    );
                                                                                }
                                                                            )
                                                                        );
                                                                    } else {
                                                                        setLoader(
                                                                            false
                                                                        );
                                                                        setPostCodeError(
                                                                            true
                                                                        );
                                                                    }
                                                                },
                                                                (data) => {
                                                                    data ===
                                                                        true &&
                                                                        setDealersData(
                                                                            []
                                                                        );
                                                                    setLoader(
                                                                        data
                                                                    );
                                                                }
                                                            )
                                                        );
                                                }}
                                            />
                                            {(!postalCodeValidation(
                                                formDetails?.zip
                                            ) ||
                                                errorResponse?.zip ||
                                                postCodeError) && (
                                                <small className="text-danger mt-2">
                                                    Postcode is niet correct
                                                </small>
                                            )}

                                            {/* <Autocomplete
                                                disablePortal
                                                disableClearable={true}
                                                id="combo-box-demo"
                                                options={postCodes}
                                                getOptionLabel={(option) =>
                                                    option?.zipCode || ""
                                                }
                                                sx={{
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-root":
                                                        {
                                                            borderRadius:
                                                                "12px",
                                                            fontFamily:
                                                                "Hyundai-Sans-Regular",
                                                        },
                                                    "& .MuiOutlinedInput-input":
                                                        {
                                                            fontSize: "14px",
                                                            color: "#495057",
                                                            fontFamily:
                                                                "Hyundai-Sans-Regular",
                                                        },
                                                    "& .MuiInputLabel-outlined":
                                                        {
                                                            fontSize: "14px",
                                                            color: "#495057",
                                                            fontFamily:
                                                                "Hyundai-Sans-Regular",
                                                        },
                                                }}
                                                onChange={(
                                                    event,
                                                    selectedOption
                                                ) => {
                                                    if (selectedOption) {
                                                        handleFormDetailsChange(
                                                            {
                                                                target: {
                                                                    name: "zip",
                                                                    value: selectedOption?.zipCode,
                                                                },
                                                            }
                                                        );
                                                        checkDealers(
                                                            selectedOption
                                                        );
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Postcode"
                                                    />
                                                )}
                                            /> */}
                                        </div>
                                    )}

                                    <FormGroup>
                                        <Input
                                            id="exampleSelect"
                                            className="custom_form_border"
                                            name="select"
                                            type="select"
                                            bsSize="lg"
                                            value={selectedDealer}
                                            onChange={(e) =>
                                                setSelectedDealer(
                                                    e.target.value
                                                )
                                            }
                                            required
                                        >
                                            {!loader ? (
                                                <>
                                                    <option
                                                        disabled
                                                        selected
                                                        value=""
                                                    >
                                                        Kies een dealer
                                                    </option>
                                                    {dealersData.length > 0 &&
                                                        dealersData.map(
                                                            (option, index) =>
                                                                option.Dealernaam && (
                                                                    <option
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            option.Dealernaam
                                                                        }
                                                                    >
                                                                        {
                                                                            option.Dealernaam
                                                                        }
                                                                    </option>
                                                                )
                                                        )}
                                                    ,
                                                </>
                                            ) : (
                                                <option>
                                                    Zoeken naar dichtsbijzijnde
                                                    dealer…
                                                </option>
                                            )}
                                        </Input>
                                    </FormGroup>

                                    <h6 className="mb-4">
                                        De gekozen dealer neemt telefonisch
                                        contact met je op voor het bespreken van
                                        je wensen voor de berekening.
                                    </h6>
                                    <Input
                                        type="checkbox"
                                        value={bootCheck}
                                        onChange={() =>
                                            setBootCheck(!bootCheck)
                                        }
                                        style={{ opacity: "0.001" }}
                                    />

                                    <div className="text-right my-4">
                                        <button
                                            className={`custom_submit_btn ${
                                                loading &&
                                                "custom_submit_btn_loading "
                                            } `}
                                            type="submit"
                                            // onClick={goThankPage}
                                            disabled={
                                                !nameValidation(
                                                    formDetails?.firstname
                                                ) ||
                                                !nameValidation(
                                                    formDetails?.lastname
                                                ) ||
                                                !emailValidation(
                                                    formDetails?.email
                                                ) ||
                                                !phoneValidation(
                                                    formDetails?.phone_number
                                                ) ||
                                                loading
                                            }
                                        >
                                            {loading ? (
                                                <Spinner size={"lg"} />
                                            ) : (
                                                <>
                                                    Aanvraag afronden
                                                    <FaLongArrowAltRight className="custom_submit_icon" />
                                                </>
                                            )}
                                        </button>
                                    </div>
                                </Form>

                                <h6 className="custom_font_size_h6">
                                    Jouw gegevens worden alleen gebruikt voor de
                                    berekening van jouw leasetarief en worden
                                    niet gedeeld met andere partijen. De
                                    berekening en uitgebrachte offertes zijn
                                    <b> gratis en vrijblijvend</b>, er zijn geen
                                    verplichtingen aan verbonden! De uitkomst
                                    van de berekening ontvang je telefonisch
                                    en/of per e-mail.
                                </h6>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ContactForm;

// Select Input Options
const options = ["Option 1", "Option 2", "Option 3"];
