const initState = {
    publisher_id: "",
    site_subid: "",
    site_custom_url: "",
    site_custom_name: "",
};

const leadUrlDataReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_PUBLISHER_ID":
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };

        default:
            return { ...state }; // Return the state directly in the default case
    }
};

export default leadUrlDataReducer;
