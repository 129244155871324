import axios from "axios";
const baseDomain = "https://us-central1-republish-react.cloudfunctions.net/app";
const baseURL = `${baseDomain}/`;
let axiosObj;
axiosObj = axios.create({
    baseURL,
    // headers: {
    //     Authorization: `Basic MTg1OmFiODIyMWQ0YTMxNzBkODk1NDI4ODA0NTlhYmY3OTgxN2FlMzY3YzI=`,
    // },
});
export default axiosObj;
