// Root Reducer
import { combineReducers } from "redux";
import postLeadReducer from "./posttLeadReducer";
import leadUrlDataReducer from "./leadUrlData";

let rootReducer = combineReducers({
    postLead: postLeadReducer,
    leadUrlData: leadUrlDataReducer,
});

export default rootReducer;
