import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

function ThankYouPage() {
    const { leadDetails } = useSelector((state) => state.postLead);
    const [checkVersion, setCheckVersion] = useState();
    useEffect(() => {
        const currentUrl = window.location.pathname;
        const paramValue = currentUrl.substring(1);
        setCheckVersion(paramValue);
    }, []);
    return (
        <>
            <Container
                fluid
                className={`bg_img_container ${
                    (checkVersion === "kona-1/thankyou" ||
                        checkVersion === "konazakelijk/thankyou" ||
                        checkVersion === "kona-2/thankyou") &&
                    "bg_img_container_kona"
                }`}
            >
                <Row className="justify-content-center align-items-center">
                    <Col xs="12" lg="9" md="10" xl="6" className="py-5">
                        <Card className="card_padding  card_custom_padding ">
                            <CardBody className="thankyou_styling">
                                <h1>
                                    Bedankt voor het invullen van het formulier.
                                </h1>
                                <h5>
                                    Onze dealer neemt binnenkort contact met jou
                                    op.
                                </h5>

                                {leadDetails?.profile &&
                                    (checkVersion === "kona-1/thankyou" ||
                                    checkVersion === "kona-2/thankyou" ? (
                                        <img
                                            referrerPolicy="no-referrer-when-downgrade"
                                            src={`https://republish.topleaseauto.nl/m/6212/b65964d3ede7/?event=7259&unique_conversion_id=${leadDetails?.profile?.id}`}
                                            style={{
                                                width: "1px",
                                                height: "1px",
                                                border: "0px",
                                            }}
                                        ></img>
                                    ) : checkVersion ===
                                      "konazakelijk/thankyou" ? (
                                        <img
                                            referrerpolicy="no-referrer-when-downgrade"
                                            src={`https://republish.topleaseauto.nl/m/6243/0f0d4fae4dd0/?event=7299&unique_conversion_id=${leadDetails?.profile?.id}`}
                                            style={{
                                                width: "1px",
                                                height: "1px",
                                                border: "0px",
                                            }}
                                        ></img>
                                    ) : (
                                        <img
                                            referrerPolicy="no-referrer-when-downgrade"
                                            src={`https://republish.topleaseauto.nl/m/6190/13f48bd4ecfb/?event=7228&unique_conversion_id=${leadDetails?.profile?.id}`}
                                            style={{
                                                width: "1px",
                                                height: "1px",
                                                border: "0px",
                                            }}
                                        ></img>
                                    ))}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ThankYouPage;
