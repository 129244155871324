export const versionList = [
    {
        description: "Actieve rijbaanassistentie (LKA)",
        iDrive: true,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Cruise Control met limiet",
        iDrive: true,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Noodremassistent (FCA) met voetgangerherkenning",
        iDrive: true,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "eCall (Emergency Call)",
        iDrive: true,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Start/Stop systeem",
        iDrive: true,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Achterbank in delen neerklapbaar",
        iDrive: true,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Zij- en gordijnairbags",
        iDrive: true,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "ABS",
        iDrive: true,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Vermoeidheidsherkenning",
        iDrive: true,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "8 inch multimediascherm",
        iDrive: false,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "USB aansluiting",
        iDrive: false,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Android Auto (voorbereiding) / Apple CarPlay",
        iDrive: false,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Airconditioning (manueel)",
        iDrive: false,
        comfort: true,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    //////
    {
        description: "8 inch multimediascherm met navigatie",
        iDrive: false,
        comfort: false,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Achteruitrijcamera met dynamische hulplijnen",
        iDrive: false,
        comfort: false,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Snelheidsbordenherkenning (ISLW)",
        iDrive: false,
        comfort: false,
        comfortSmart: true,
        premium: true,
        nLine: true,
    },
    {
        description: "Airconditioning (volautomatisch)",
        iDrive: false,
        comfort: false,
        comfortSmart: false,
        premium: true,
        nLine: true,
    },
    {
        description: "Parkeersensoren achter",
        iDrive: false,
        comfort: false,
        comfortSmart: false,
        premium: true,
        nLine: true,
    },
    {
        description: "3D afwerking interieurdelen",
        iDrive: false,
        comfort: false,
        comfortSmart: false,
        premium: true,
        nLine: true,
    },
    {
        description: "16-inch lichtmetalen velgen",
        iDrive: false,
        comfort: false,
        comfortSmart: false,
        premium: false,
        nLine: true,
    },
    {
        description: "Verwarmde stoelen voor",
        iDrive: false,
        comfort: false,
        comfortSmart: false,
        premium: false,
        nLine: true,
    },
    {
        description: "Draadloos telefoon opladen",
        iDrive: false,
        comfort: false,
        comfortSmart: false,
        premium: false,
        nLine: true,
    },
    {
        description: "Verwarmd stuurwiel",
        iDrive: false,
        comfort: false,
        comfortSmart: false,
        premium: false,
        nLine: true,
    },
];

export const pricingList = [
    // {
    //     aantalMaanden: "72 maanden",
    //     aantalkm: "5.000",
    //     maandbedrag: "275",
    // },
    {
        aantalMaanden: "60 maanden",
        aantalkm: "10.000",
        maandbedrag: "319 p/m",
    },
    {
        aantalMaanden: "60 maanden",
        aantalkm: "20.000",
        maandbedrag: "367 p/m",
    },
    {
        aantalMaanden: "48 maanden",
        aantalkm: "10.000",
        maandbedrag: "349 p/m",
    },
    {
        aantalMaanden: "48 maanden",
        aantalkm: "20.000",
        maandbedrag: "393 p/m",
    },
];
export const konapricingList = [
    {
        aantalMaanden: "60 maanden",
        aantalkm: "10.000",
        maandbedrag: "559",
        maandbedrag1: "€498 (1e 48 maanden)",
    },
    {
        aantalMaanden: "60 maanden",
        aantalkm: "20.000",
        maandbedrag: "632",
        maandbedrag1: "€571 (1e 48 maanden)",
    },
    {
        aantalMaanden: "48 maanden",
        aantalkm: "10.000",
        maandbedrag: "599",
        maandbedrag1: "€538",
    },
    {
        aantalMaanden: "48 maanden",
        aantalkm: "20.000",
        maandbedrag: "669",
        maandbedrag1: "€608",
    },
];

export const newKonapricingList = [
    {
        aantalMaanden: "60 maanden",
        aantalkm: "10.000",
        maandbedrag: "449,-",
    },
    {
        aantalMaanden: "60 maanden",
        aantalkm: "20.000",
        maandbedrag: "515,-",
    },
    {
        aantalMaanden: "48 maanden",
        aantalkm: "10.000",
        maandbedrag: "473,-",
    },
    {
        aantalMaanden: "48 maanden",
        aantalkm: "20.000",
        maandbedrag: "535,-",
    },
];
