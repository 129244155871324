import React, { useEffect, useRef } from "react";
import { standardEquipment } from "../const/StandardEquipmentList";
const KonaStandardEquipment = () => {
    return (
        <>
            <div
                className={`standard_equipment_section w-100 d-flex justify-content-center flex-column                   
                `}
            >
                <div className="standard_equipment_section_title mt-4 p-4 d-flex justify-content-center">
                    <h1>Standaarduitrusting</h1>
                </div>
                <div className="standard_equipment_section_wrapper d-flex justify-content-center mt-2 p-0 mb-5">
                    <div className="standard_equipment_section_wrapper_card  p-4">
                        {standardEquipment.map((item) => (
                            <div className="d-flex align-items-center">
                                <img
                                    className="standard_equipment_section_wrapper_card_img_container ml-4 mt-3 mr-2 h-25"
                                    src={item.icon}
                                ></img>

                                <h5 className="m-0 ml-4 mt-3 mr-3">
                                    {item.title}
                                </h5>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default KonaStandardEquipment;
