import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "reactstrap";
import {
    konapricingList,
    newKonapricingList,
    pricingList,
} from "../const/Object";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import {
    ResetKonaLeadsAnswers,
    ResetLeadsAnswers,
} from "../store/actions/postLeadAction";
const PriceSection = ({ version }) => {
    const tableRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const [pricingListData, setPricingListData] = useState([]);
    const handleScrollLeft = () => {
        if (tableRef.current) {
            tableRef.current.scrollLeft -= 50;
        }
    };

    const handleScrollRight = () => {
        if (tableRef.current) {
            tableRef.current.scrollLeft += 50;
        }
    };
    useEffect(() => {
        if (version === "kona-1") {
            setPricingListData(konapricingList);
        } else if (version === "konazakelijk") {
            setPricingListData(newKonapricingList);
        } else {
            setPricingListData(pricingList);
        }
    });
    return (
        <div
            className={`${
                version === "konazakelijk" ? "mb-0" : "mb-5"
            } h-100 w-100 d-flex justify-content-start align-items-center flex-column  features-pricing flex-nowrap `}
        >
            <div className="mt-5 w-100 d-flex justify-content-center">
                <h1
                    className={`title mt-5 ${
                        (version === "kona-1" || version === "konazakelijk") &&
                        "w-title"
                    }`}
                >
                    {/* Comfort 65 kWh (bereik tot 514 km) */}
                    {version === "kona-1"
                        ? "Prijzentabel KONA Electric "
                        : version === "konazakelijk"
                        ? "Prijzentabel KONA Electric Comfort 65 kWh (bereik tot 514 km)"
                        : "Prijzentabel i10 Comfort"}
                </h1>
            </div>
            <div className="w-100 position-relative container d-flex justify-content-center mt-4  table-container">
                <div className="overflow-auto" ref={tableRef}>
                    <Table
                        className={`${
                            version === "kona-1" || version === "konazakelijk"
                                ? "custom-w-size"
                                : "max-w"
                        }`}
                    >
                        <thead className="bg-light pricing-table-body">
                            <tr className="bg-light">
                                <th className="  feature-title pl-4">
                                    <span className="bg-blue  bg-blue-span d-flex flex-flex-nowrap justify-content-center align-items-center h-100 w-100">
                                        <p>
                                            {version === "kona-1"
                                                ? "Looptijd"
                                                : "Aantal maanden"}
                                        </p>
                                    </span>
                                </th>

                                <th className=" ">
                                    <span className="white-span d-flex flex-column justify-content-center align-items-center h-100 w-100">
                                        Aantal km
                                        {version === "kona-1" && (
                                            <p className="m-0 custom_style">
                                                per jaar
                                            </p>
                                        )}
                                    </span>
                                </th>

                                <th className="">
                                    {" "}
                                    <span className="white-span d-flex flex-column justify-content-center align-items-center h-100 w-100">
                                        Maandbedrag
                                        {version === "kona-1" && (
                                            <p className="m-0 custom_style">
                                                v.a.
                                            </p>
                                        )}
                                    </span>
                                </th>
                                {version === "kona-1" && (
                                    <th className="">
                                        {" "}
                                        <span className="white-span d-flex flex-column justify-content-center align-items-center h-100 w-100">
                                            Maandbedrag
                                            <p className="m-0 custom_style">
                                                incl. SEPP subsidie v.a.
                                            </p>
                                        </span>
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="bg-light  pricing-table-body">
                            {pricingListData.length > 0 &&
                                pricingListData?.map((price, index) => (
                                    <tr>
                                        <td
                                            className={`${
                                                index === 0
                                                    ? "first-row"
                                                    : "child-row"
                                            } p-0  pricing-first-column ${
                                                index ===
                                                    pricingListData.length -
                                                        1 && "lastrow"
                                            }`}
                                        >
                                            <span className="w-100 h-100 parent-span bg-span">
                                                <span className="h-100 w-100 d-flex justify-content-start align-items-center flex-nowrap">
                                                    {price.aantalMaanden}
                                                </span>
                                            </span>
                                        </td>
                                        <td
                                            className={`${
                                                index === 0
                                                    ? "first-row"
                                                    : "child-row"
                                            } p-0 ${
                                                index ===
                                                    pricingListData.length -
                                                        1 && "lastrow"
                                            }`}
                                        >
                                            <span className="parent-span w-100 h-100 ">
                                                <span className="h-100 w-100 d-flex justify-content-start align-items-center pl-1 pr-1 price_section_row_data_margin ">
                                                    {price.aantalkm}
                                                </span>
                                            </span>
                                        </td>
                                        <td
                                            className={`${
                                                index === 0
                                                    ? "first-row"
                                                    : "child-row"
                                            } p-0 ${
                                                index ===
                                                    pricingListData.length -
                                                        1 && "lastrow"
                                            }`}
                                        >
                                            <span className="w-100 h-100 parent-span">
                                                <span className="h-100 w-100 d-flex justify-content-start align-items-center price_section_row_data_margin ">
                                                    €{price.maandbedrag}
                                                </span>
                                            </span>
                                        </td>
                                        {version === "kona-1" && (
                                            <td
                                                className={` ${
                                                    index === 0
                                                        ? "first-row"
                                                        : "child-row"
                                                } p-0 ${
                                                    index ===
                                                        pricingListData.length -
                                                            1 && "lastrow"
                                                } `}
                                            >
                                                <span className="w-100 h-100 custom_padding parent-span">
                                                    <span className="pl-0 pr-0 h-100 w-100 d-flex justify-content-start align-items-center ">
                                                        {price.maandbedrag1}
                                                    </span>
                                                </span>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                </div>
                <div className="  position-absolute  fixed-bottom pricing-scroll-buttons d-flex justify-content-start ">
                    <Button
                        className=" rounded-circle bg-blue  pt-3 pb-3 d-flex justify-content-center align-items-center mr-4"
                        onClick={handleScrollLeft}
                    >
                        <span>
                            <svg
                                width="10"
                                height="12"
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.0336 1.00008L2.0672 7.03125L8.0336 13.0624"
                                    stroke="white"
                                    //stroke-opacity="0.54"
                                    stroke-width="2.81264"
                                />
                            </svg>
                        </span>
                    </Button>
                    <Button
                        className="rounded-circle bg-blue pt-3 pb-3 d-flex justify-content-center align-items-center"
                        onClick={handleScrollRight}
                    >
                        <span>
                            <svg
                                width="10"
                                height="12"
                                viewBox="0 0 14 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M5.9664 1.00008L11.9328 7.03125L5.9664 13.0624"
                                    stroke="white"
                                    stroke-width="2.81264"
                                />
                            </svg>
                        </span>
                    </Button>
                </div>
            </div>
            <div className="w-100 d-flex justify-content-center align-items-center mt-4 ">
                <Button
                    className="bg-blue p-4  pl-4 pr-4 d-flex mb-5"
                    onClick={() => {
                        version === "kona-1" || version === "konazakelijk"
                            ? dispatch(ResetKonaLeadsAnswers())
                            : dispatch(ResetLeadsAnswers());

                        {
                            version === "kona-1"
                                ? history.push("/kona-1/form")
                                : version === "konazakelijk"
                                ? history.push("/konazakelijk/form")
                                : history.push("/form");
                        }
                    }}
                >
                    {" "}
                    <span className="h4 m-0 mr-2 font-weight-bolder">
                        {version === "konazakelijk"
                            ? "Bereken mijn maandbedrag"
                            : " Vraag offerte aan"}
                    </span>
                    <span className="pt-1 pl-2">
                        <svg
                            width="22"
                            height="15"
                            viewBox="0 0 24 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M23.725 5.87313C23.7247 5.87282 23.7245 5.87245 23.7242 5.87214L18.8256 0.420521C18.4586 0.012122 17.865 0.0136418 17.4997 0.424086C17.1344 0.834477 17.1359 1.49826 17.5028 1.90672L20.7918 5.56685H0.9375C0.419719 5.56685 0 6.03621 0 6.61523C0 7.19426 0.419719 7.66362 0.9375 7.66362H20.7917L17.5029 11.3238C17.1359 11.7322 17.1345 12.396 17.4997 12.8064C17.865 13.2169 18.4587 13.2183 18.8256 12.8099L23.7242 7.35833C23.7245 7.35802 23.7247 7.35765 23.7251 7.35734C24.0922 6.94752 24.0911 6.28159 23.725 5.87313Z"
                                fill="white"
                            />
                        </svg>
                    </span>
                </Button>
            </div>
        </div>
    );
};

export default PriceSection;
