import React from "react";
import Slider from "react-slick";
import { Button, Container } from "reactstrap";
import {
    ResetKonaLeadsAnswers,
    ResetLeadsAnswers,
} from "../store/actions/postLeadAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SlickSlider = ({ version }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        autoplay: true,
        scrollbars: true,
        autoplaySpeed: 2000,
        cssEase: "linear",
        centerMode: true,
        centerPadding: 40,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <Container className=" pt-1 mt-5 mb-5 slick-slider-hyundai">
            <div className="text-center slick-header mb-5 mt-5">
                <p>
                    {version === "kona-1" ||
                    version === "konazakelijk" ||
                    version === "kona-2"
                        ? "Hyundai KONA Electric"
                        : "Hyundai i10"}
                </p>
                <h1>
                    {version === "kona-1" ||
                    version === "konazakelijk" ||
                    version === "kona-2"
                        ? "Een futuristische nieuwe look"
                        : "Een frisse kijk op een gedurfd ontwerp"}
                </h1>
            </div>
            <div className="slick-body">
                <Slider {...settings}>
                    <div>
                        <div
                            className={`${
                                version === "kona-1" ||
                                version === "konazakelijk" ||
                                version === "kona-2"
                                    ? "kona_img-2"
                                    : "img-2"
                            } img-container`}
                        />
                    </div>
                    <div>
                        <div
                            className={`${
                                version === "kona-1" ||
                                version === "konazakelijk" ||
                                version === "kona-2"
                                    ? "kona_img-3"
                                    : "img-3"
                            } img-container`}
                        />
                    </div>
                    <div>
                        <div
                            className={`${
                                version === "kona-1" ||
                                version === "konazakelijk" ||
                                version === "kona-2"
                                    ? "kona_img-4"
                                    : "img-4"
                            } img-container`}
                        />
                    </div>
                    <div>
                        <div
                            className={`${
                                version === "kona-1" ||
                                version === "konazakelijk" ||
                                version === "kona-2"
                                    ? "kona_img-1"
                                    : "img-1"
                            } img-container`}
                        />
                    </div>
                </Slider>
            </div>
            {version === "kona-2" && (
                <div className="w-100 d-flex justify-content-center align-items-center mt-5 ">
                    <Button
                        className={`${
                            version === "kona-2" ? "bg_orange" : "bg-blue"
                        } p-4  pl-4 pr-4 d-flex mt-5`}
                        onClick={() => {
                            dispatch(ResetKonaLeadsAnswers());

                            history.push("/kona-2/form");
                        }}
                    >
                        {" "}
                        <span className="h4 m-0 mr-2 font-weight-bolder">
                            Bereken mijn leasetarief
                        </span>
                        <span className="pt-1 pl-2">
                            <svg
                                width="22"
                                height="15"
                                viewBox="0 0 24 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M23.725 5.87313C23.7247 5.87282 23.7245 5.87245 23.7242 5.87214L18.8256 0.420521C18.4586 0.012122 17.865 0.0136418 17.4997 0.424086C17.1344 0.834477 17.1359 1.49826 17.5028 1.90672L20.7918 5.56685H0.9375C0.419719 5.56685 0 6.03621 0 6.61523C0 7.19426 0.419719 7.66362 0.9375 7.66362H20.7917L17.5029 11.3238C17.1359 11.7322 17.1345 12.396 17.4997 12.8064C17.865 13.2169 18.4587 13.2183 18.8256 12.8099L23.7242 7.35833C23.7245 7.35802 23.7247 7.35765 23.7251 7.35734C24.0922 6.94752 24.0911 6.28159 23.725 5.87313Z"
                                    fill="white"
                                />
                            </svg>
                        </span>
                    </Button>
                </div>
            )}
        </Container>
    );
};

export default SlickSlider;
