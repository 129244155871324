import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "reactstrap";
import arrow from "../assets/img/rightArrow.svg";
import { useHistory } from "react-router-dom";
import {
    ResetKonaLeadsAnswers,
    ResetLeadsAnswers,
} from "../store/actions/postLeadAction";
import { useDispatch } from "react-redux";
import tick from "../assets/img/tick.svg";
const HeroCardSection = ({ version }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [checkVersion, setCheckVersion] = useState();
    useEffect(() => {
        const currentUrl = window.location.pathname;
        const paramValue = currentUrl.substring(1);
        setCheckVersion(paramValue);
    }, []);
    return (
        <>
            <div
                className={`card-div ${
                    (version === "kona-1" ||
                        version === "konazakelijk" ||
                        version === "kona-2") &&
                    "cutom_card-div_margin"
                }`}
            >
                <Card className="border-none p-4 p-md-3">
                    {version === "konazakelijk" ? (
                        <div className="d-flex flex-wrap justify-content-between main_Wrapper">
                            <div className="d-flex align-items-center  w-100 custom_wrap mb-0">
                                <div>
                                    {" "}
                                    <p className="custom_p_left text-md-start text-left custom_width custom_text_center  mb-0 text-site-primary ">
                                        Zakelijke lease
                                        <br />
                                        vanaf €449 p/m
                                    </p>
                                </div>
                                {/* <p className="custom_p_left text-md-start text-left custom_width custom_text_center  mb-0 text-site-primary ">
                                    Zakelijk lease
                                    <br />
                                    vanaf €449 p/m
                                </p> */}
                                <div className="border   divider">
                                    {/* <hr className="horizontal_divider fa-rotate-90"></hr>
                                    <hr className="vertical_divider pl-2 ml-3"></hr> */}
                                </div>
                                <div>
                                    <p className="text-md-start text-left custom_width custom_text_center  mb-0 text-site-primary font-weight-light font_family">
                                        Netto bijtelling <br /> vanaf €189 p/m
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center justify-content-md-end mt-md-0 mt-2">
                                <Button
                                    color=" h-100 p-3 pt-4 pb-4"
                                    onClick={() => {
                                        checkVersion === "kona-2" ||
                                        version === "konazakelijk" ||
                                        checkVersion === "kona-1"
                                            ? dispatch(ResetKonaLeadsAnswers())
                                            : dispatch(ResetLeadsAnswers());

                                        checkVersion === "i10-2"
                                            ? history.push("/i10-2/form")
                                            : checkVersion === "kona-1"
                                            ? history.push("/kona-1/form")
                                            : checkVersion === "kona-2"
                                            ? history.push("/kona-2/form")
                                            : checkVersion === "konazakelijk"
                                            ? history.push("/konazakelijk/form")
                                            : history.push("/form");
                                    }}
                                >
                                    {checkVersion === "i10-2"
                                        ? "Bereken mijn maandbedrag"
                                        : checkVersion === "kona-2"
                                        ? "Bereken mijn leasetarief"
                                        : version === "konazakelijk"
                                        ? "Bereken mijn maandbedrag"
                                        : " Vraag offerte aan"}
                                    <img src={arrow} alt="" className="ml-3" />
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <Row>
                            <Col
                                md="6"
                                className={`d-flex align-items-center custom_col_align ${
                                    version === "konazakelijk" &&
                                    "justify-content-center"
                                } `}
                            >
                                <p className="text-md-start text-left  custom_text_center  mb-0 text-site-primary ml-2 pl-2">
                                    Private Lease nu tijdelijk
                                    <br />
                                    vanaf{" "}
                                    {checkVersion === "kona-1"
                                        ? "€498"
                                        : checkVersion === "kona-2"
                                        ? "€464"
                                        : version === "1"
                                        ? "€319"
                                        : "€319"}{" "}
                                    p/m*
                                </p>

                                {/* <p className="text-md-start text-left  custom_text_center  mb-0 text-site-primary ml-2 pl-2">
                                Private Lease nu tijdelijk
                                <br />
                                vanaf{" "}
                                {checkVersion === "kona-1" ||
                                version === "konazakelijk" ||
                                checkVersion === "kona-2"
                                    ? "€498"
                                    : "€275"}{" "}
                                p/m*
                            </p> */}
                            </Col>
                            <Col
                                md="6"
                                className="d-flex justify-content-center align-items-center justify-content-md-end mt-md-0 mt-2"
                            >
                                <Button
                                    color={`h-100 p-3 pt-4 ${
                                        checkVersion === "kona-2"
                                            ? "pb-3  bg_orange "
                                            : "pb-4"
                                    }`}
                                    onClick={() => {
                                        checkVersion === "kona-2" ||
                                        version === "konazakelijk" ||
                                        checkVersion === "kona-1"
                                            ? dispatch(ResetKonaLeadsAnswers())
                                            : dispatch(ResetLeadsAnswers());

                                        checkVersion === "i10-2"
                                            ? history.push("/i10-2/form")
                                            : checkVersion === "kona-1"
                                            ? history.push("/kona-1/form")
                                            : checkVersion === "kona-2"
                                            ? history.push("/kona-2/form")
                                            : checkVersion === "konazakelijk"
                                            ? history.push("/konazakelijk/form")
                                            : history.push("/form");
                                    }}
                                >
                                    <span className="">
                                        {checkVersion === "i10-2"
                                            ? "Bereken mijn maandbedrag"
                                            : checkVersion === "kona-2"
                                            ? "Bereken mijn leasetarief"
                                            : version === "konazakelijk"
                                            ? "Bereken mijn maandbedrag"
                                            : " Vraag offerte aan"}
                                    </span>

                                    <img src={arrow} alt="" className="ml-3" />
                                    {checkVersion === "kona-2" && (
                                        <>
                                            {" "}
                                            <br />
                                            <span className="d-flex w-100 align-items-center font-weight-light p-0 font-12">
                                                <img
                                                    src={tick}
                                                    alt=""
                                                    className="m-0 p-0 mr-1 mb-1"
                                                    width={15}
                                                />{" "}
                                                100% gratis en vrijblijvend
                                            </span>
                                        </>
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Card>
            </div>
            {version === "kona-1" || version === "kona-2" ? (
                <>
                    {" "}
                    <small class="text-site-secondary d-md-block d-none">
                        * Genoemde vanafprijs van €
                        {version === "kona-2" ? "464" : "498"} is op basis van
                        60 maanden looptijd en 10.000 kilometer <br /> per jaar.
                        Dit bedrag is inclusief SEPP subsidie van €61 per maand
                        op de eerste 48 maanden.
                        <br /> Daarna wordt het maandbedrag €
                        {version === "kona-2" ? "525" : "559"} per maand.
                        {/* * Het maandbedrag van €559 geldt op basis van 72 maanden
                        en 5.000 km per jaar!
                        <br /> Bedrag is inclusief de eerste 48 maanden
                        Sepp-subsidie van €61. Daarna wordt het
                        <br /> maandbedrag €525. */}
                    </small>
                    <small class="text-site-secondary d-md-none d-block mb-4 mb-md-0">
                        * Genoemde vanafprijs van €
                        {version === "kona-2" ? "464" : "498"} is op basis van
                        60 maanden looptijd en 10.000 kilometer <br /> per jaar.
                        Dit bedrag is inclusief SEPP subsidie van € 61 per maand
                        op de eerste 48 maanden.
                        <br /> Daarna wordt het maandbedrag €
                        {version === "kona-2" ? "525" : "559"} per maand.
                        {/* * Het maandbedrag van €559 geldt op basis van 72 maanden
                        en 5.000 km per jaar!
                        <br /> Bedrag is inclusief de eerste 48 maanden
                        Sepp-subsidie van €61. Daarna wordt het
                        <br /> maandbedrag €525. */}
                    </small>
                </>
            ) : version === "konazakelijk" ? (
                <>
                    <small class="text-site-secondary d-md-block d-none">
                        *Het maandbedrag van €449 geldt op basis van 60 maanden
                        en 10.000 km per jaar.
                        <br /> Bedrag is exclusief btw.
                    </small>
                    <small class="text-site-secondary d-md-none d-block mb-4 mb-md-0">
                        *Het maandbedrag van €449 geldt op basis van 60 maanden
                        en 10.000 km per jaar.
                        <br /> Bedrag is exclusief btw.
                    </small>
                </>
            ) : (
                <>
                    <small class="text-site-secondary d-md-block d-none">
                        * Het maandbedrag van €
                        {version === "1" ? "319 " : "310 "}
                        geldt op basis van een looptijd van{" "}
                        {version === "1" ? "60" : "60"} <br /> maanden en{" "}
                        {version === "1" ? "10.000" : "10.000"} km per jaar bij
                        de i10 Comfort!
                    </small>
                    <small class="text-site-secondary d-md-none d-block mb-4 mb-md-0">
                        * Het maandbedrag van €
                        {version === "1" ? "319 " : "319"}
                        geldt op basis van een looptijd van{" "}
                        {version === "1" ? "60" : "60"} maanden en{" "}
                        {version === "1" ? "10.000" : "10.000"} km per jaar bij
                        de i10 Comfort!
                    </small>
                </>
            )}
        </>
    );
};

export default HeroCardSection;
