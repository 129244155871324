import React from "react";
import { FaArrowUp } from "react-icons/fa";
import Arrowup from "../assets/img/angle-up-solid_white.svg";

const ScrollToTopButton = () => {
    // Function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className="position-relative text-right custom_bg d-block d-md-none">
            <button onClick={scrollToTop} className="scroll_to_top_btn">
                <img
                    src={Arrowup}
                    alt="Scroll to Top"
                    className="scroll_to_top_icon"
                />
            </button>
        </div>
    );
};

export default ScrollToTopButton;
