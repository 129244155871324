import { RepositoryFactory } from "../../repository/RepositoryFactory";

let LeadRepo = RepositoryFactory.get("leads");
export const createPostLead =
    (payload, bootcheck, onSuccess = () => {}, onError = () => {}) =>
    async (dispatch) => {
        await dispatch(setLeadLoading(true));
        if (bootcheck) {
            onSuccess();
            await dispatch(setLeadLoading(false));
        } else {
            try {
                let data = await LeadRepo.postLead(payload);
                dispatch({
                    type: "SET_LEAD_DETAILS",
                    payload: data?.data,
                });
                onSuccess();
                await dispatch(setLeadLoading(false));
            } catch (e) {
                if (e.response.data.error == "Conflict - duplicate") {
                    onSuccess();
                } else {
                    let error = e.response.data.error
                        .match(/'([^']+)'/)[0]
                        .slice(1, -1);
                    dispatch({
                        type: "ERROR_RESPONSE",
                        payload: { [error]: true },
                    });
                }
                await dispatch(setLeadLoading(false));
            }
        }
    };
export const resetErrorResponse = (data) => (dispatch) => {
    dispatch({
        type: "RESET_ERROR_RESPONSE_NULL",
    });
};
export const addLeadsData = (data) => (dispatch) => {
    dispatch({
        type: "ADD_LEAD_DATA",
        payload: data,
    });
};
export const ResetLeadsAnswers = () => (dispatch) => {
    dispatch({
        type: "RESET_LEAD",
    });
};
export const ResetKonaLeadsAnswers = () => (dispatch) => {
    dispatch({
        type: "RESET_LEAD_KONA",
    });
};
export const updateLeadsData = (data) => (dispatch) => {
    dispatch({
        type: "REMOVE_ANSWER_DATA",
        payload: data,
    });
};

export const setLeadLoading = (val) => async (dispatch) => {
    dispatch({
        type: "SET_LEAD_LOADING",
        payload: val,
    });
};
export const setErrorResponse = () => async (dispatch) => {
    dispatch({
        type: "ERROR_RESPONSE",
        payload: null,
    });
};
export const addLeadsUrlData = (data) => (dispatch) => {
    dispatch({
        type: "SET_PUBLISHER_ID",
        payload: data,
    });
};
