import { useDispatch, useSelector } from "react-redux";
import {
    addLeadsData,
    addLeadsUrlData,
    createPostLead,
} from "../store/actions/postLeadAction";
import Header from "../components/Header";
import AllInclusive from "../components/AllInclusive";
import HeroSection from "../components/HeroSection";
import PriceSection from "../components/PriceSection";
import SlickSlider from "../components/SlickSlideber";
import VersionAndFeatures from "../components/VersionAndFeatures";

import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { useParams } from "react-router";

const MainView2 = (props) => {
    const targetRef = useRef(null);
    const { id } = useParams();
    const dispatch = useDispatch();
    const data = useSelector((state) => state);

    const handleClick = () => {
        dispatch(createPostLead("hello"));
    };
    useEffect(() => {
        // Parse the query string from the URL
        const queryParams = new URLSearchParams(window.location.search);

        // Get the value of the 'click' parameter
        const clickParam = queryParams.get("click");
        dispatch(
            addLeadsUrlData({
                name: "publisher_id",
                value: clickParam,
            })
        );
        // Get the value of the 'site' parameter
        const siteParam = queryParams.get("site");
        dispatch(
            addLeadsUrlData({
                name: "site_subid",
                value: siteParam,
            })
        );
        dispatch(
            addLeadsUrlData({
                name: "site_custom_url",
                value: "https://hyundai.topleaseauto.nl/i10-2",
            })
        );
        dispatch(
            addLeadsUrlData({
                name: "site_custom_name",
                value: "laagdempelig_hyundai_i10",
            })
        );
    }, []);
    useEffect(() => {
        const script1 = document.createElement("script");
        script1.async = true;
        script1.src =
            "https://www.googletagmanager.com/gtag/js?id=G-L93N86FQ94";
        document.head.appendChild(script1);
        const script2 = document.createElement("script");
        script2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-L93N86FQ94');
        `;
        document.head.appendChild(script2);
        return () => {
            document.head.removeChild(script1);
            document.head.removeChild(script2);
        };
    }, []);
    return (
        <div className="position-relative">
            <HeroSection />
            <AllInclusive />
            <SlickSlider />
            {/* <VersionAndFeatures version="2" /> */}
        </div>
    );
};

export default MainView2;
