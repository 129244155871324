export const leadsData = {
    language: "nl_NL",
    firstname: "",
    lastname: "",
    email: "",
    gender: "",
    street: "",
    city: "",
    zip: "",
    phone_number: "",
    answers: [],
};

// ["1011", "1022", "1022-1033-1099", "4798"];
