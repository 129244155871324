import React from "react";
import girlWithCar from "../assets/img/girlWithCar1.jpg";
import konaInclusiveImg from "../assets/img/kona_inclusive.jpeg";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import img2 from "../assets/img/coins 1.svg";
import img1 from "../assets/img/steering wheel 1.svg";
import img3 from "../assets/img/car insuranced 1.svg";
import img5 from "../assets/img/card wallet 1.svg";
import img4 from "../assets/img/emergency 1.svg";
import img6 from "../assets/img/clock.svg";
import bluetick from "../assets/img/bluetick.svg";
import ScrollToTopButton from "./ScrollToTopButton";
const AllInclusive = ({ version }) => {
    const cardData = [
        { img: img1, title: "Rijden in een nieuwe Hyundai" },
        { img: img2, title: "Aantrekkelijk vast bedrag p/m" },
        version === "konazakelijk"
            ? { img: img3, title: "Inc. verzekering en onderhoud" }
            : { img: img3, title: "Incl. verzekering en reparatie" },
        version === "konazakelijk"
            ? { img: img6, title: "24 uur per dag bereikbaar" }
            : { img: img4, title: "Schadeherstel en onderhoud" },
        { img: img5, title: "Geen verborgen kosten" },
    ];
    return (
        <>
            <ScrollToTopButton />
            <div className="inclusive-section   " id="inclusive-section">
                <Row>
                    <Col lg="6" className="relative order-lg-0 order-1">
                        {version === "kona-2" ? (
                            <div className="inclusive-section__video p-5 d-flex  mt-2">
                                <iframe
                                    className="ml-5 mt-5"
                                    width="500"
                                    height="315"
                                    src="https://www.youtube.com/embed/itmxuteB_xs?autoplay=1&mute=1&loop=1&playlist=itmxuteB_xs&si=6r5yp8B-iNPVifXt"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin"
                                    allowfullscreen
                                ></iframe>
                            </div>
                        ) : (
                            <img
                                src={
                                    version === "kona-1" ||
                                    version === "konazakelijk"
                                        ? konaInclusiveImg
                                        : girlWithCar
                                }
                                className="inclusive-section__img"
                                alt=""
                            />
                        )}
                    </Col>
                    <Col lg="6" className=" py-2 py-lg-5  order-lg-1 order-0">
                        <Container>
                            <p className="inclusive-section__sub-title text-site-primary mt-3 mt-lg-5 mb-0">
                                {version === "kona-1" ||
                                version === "konazakelijk" ||
                                version === "kona-2"
                                    ? "Hyundai KONA Electric"
                                    : "Hyundai i10"}
                            </p>

                            <h1
                                className={`my-lg-3 my-2 inclusive-section__title text-site-primary`}
                            >
                                {version === "konazakelijk" ? (
                                    <>
                                        De grote voordelen van <br /> Hyundai
                                        Zakelijke Lease
                                    </>
                                ) : version === "kona-2" ? (
                                    <>
                                        All-in maandbedrag met <br /> Hyundai
                                        Private Lease:
                                    </>
                                ) : (
                                    <>
                                        All-inclusive genieten met <br />{" "}
                                        Hyundai Private Lease
                                    </>
                                )}
                            </h1>
                            {version === "konazakelijk" ? (
                                <ul className="my-3 my-lg-5 inclusive-section__list_konazakelijk">
                                    <li>Zeer scherpe tarieven</li>
                                    <li>Vast maandbedrag per maand</li>
                                    <li>
                                        Geen omkijken naar onderhoud en
                                        verzekeringen
                                    </li>
                                    <li>
                                        Nieuwe auto met inbegrepen rente en
                                        afschrijving
                                    </li>
                                    <li>Pechhulp in binnenland en Europa</li>
                                    <li>
                                        Bandvervanging, schadeherstel en
                                        glasreparatie gedekt
                                    </li>
                                    <li>
                                        Al het onderhoud bij jouw persoonlijke
                                        Hyundai-dealer
                                    </li>
                                    <li>
                                        Afleveringskosten en
                                        verwijderingsbijdrage inclusief
                                    </li>
                                </ul>
                            ) : version === "kona-2" ? (
                                <ul className="my-3 my-lg-4 inclusive-section__list list-unstyled line-height ml-0">
                                    <li>
                                        <img
                                            src={bluetick}
                                            alt="Blue tick"
                                            className="mr-2 p-1"
                                        />
                                        Motorrijtuigenbelasting
                                    </li>
                                    <li>
                                        <img
                                            src={bluetick}
                                            alt="Blue tick"
                                            className="mr-2 p-1"
                                        />
                                        Verzekeringen
                                    </li>
                                    <li>
                                        <img
                                            src={bluetick}
                                            alt="Blue tick"
                                            className="mr-2 p-1"
                                        />
                                        Pechhulp in Nederland en Europa
                                    </li>
                                    <li>
                                        <img
                                            src={bluetick}
                                            alt="Blue tick"
                                            className="mr-2 p-1"
                                        />
                                        Onderhoud en schadeherstel
                                    </li>
                                    <li>
                                        <img
                                            src={bluetick}
                                            alt="Blue tick"
                                            className="mr-2 p-1"
                                        />
                                        Afleveringskosten en
                                        verwijderingsbijdrage
                                    </li>
                                </ul>
                            ) : (
                                <ul className="my-3 my-lg-5 inclusive-section__list">
                                    <li>
                                        Vast maandbedrag inclusief alle kosten,
                                        behalve laadkosten
                                    </li>
                                    <li className="mt-1">
                                        Nieuwe auto met inbegrepen rente en
                                        afschrijving
                                    </li>
                                    <li className="mt-1">
                                        Pechhulp in binnenland en Europa
                                    </li>
                                    <li>Motorrijtuigenbelasting inbegrepen</li>
                                    <li className="mt-1">
                                        Bandenvervanging, schadeherstel en
                                        glasreparatie gedekt
                                    </li>
                                    <li className="mt-1">
                                        Afleveringskosten en
                                        verwijderingsbijdrage inclusief
                                    </li>
                                    <li className="mt-1">
                                        Onderhoud inbegrepen
                                    </li>
                                    {version !== "kona-1" &&
                                        version !== "kona-2" && (
                                            <li className="mt-1">
                                                Volle tank brandstof bij
                                                aflevering
                                            </li>
                                        )}
                                </ul>
                            )}
                        </Container>
                    </Col>
                </Row>
                <Container fluid>
                    <div
                        className={`d-flex flex-wrap justify-content-center ${
                            version === "kona-2"
                                ? "inclusive-section__kona_bottom"
                                : "inclusive-section__bottom"
                        } `}
                    >
                        {cardData?.map((items) => {
                            return (
                                <div className="p-md-4 p-3 border-0 inclusive-section__card mr-2 mt-3 bg-white">
                                    <CardTitle>
                                        <img
                                            src={items.img}
                                            alt=""
                                            className="inclusive-section__card-icon"
                                        />
                                    </CardTitle>
                                    <CardBody className="p-1 ">
                                        <p className="m-0 mt-2 mb-2 mt-md-3 text-site-primary inclusive-section__card-text">
                                            {items.title}
                                        </p>
                                    </CardBody>
                                </div>
                            );
                        })}
                    </div>
                    {/* <Row className="justify-content-center">
                    {cardData.map((items, index) => {
                        return (
                            <Col lg="2" key={index} className="p-0 px-2">
                                <Card className="p-4 border-0 inclusive-section__card">
                                    <CardTitle>
                                        <img
                                            src={items.img}
                                            alt=""
                                            className="inclusive-section__card-icon"
                                        />
                                    </CardTitle>
                                    <CardBody className="p-0 ">
                                        <p className="m-0 mt-3 text-site-primary inclusive-section__card-text">
                                            {items.title}
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}
                </Row> */}
                </Container>
                {/* <Row className="w-100">
                <Col md="6" className="all-inclusive_left">
                    <img src={img} alt="" />
                </Col>
                <Col md="6">
                    <Container>
                        <div>
                            <span>Hyundai i10</span>
                        </div>
                        <h1>
                            All-inclusive genieten met Hyundai Private Lease
                        </h1>
                        <ul>
                            <li>
                                Vast maandbedrag inclusief alle kosten, behalve
                                brandstof
                            </li>
                            <li>
                                Nieuwe auto met inbegrepen rente en afschrijving
                            </li>
                            <li>Pechhulp in binnenland en Europa</li>
                            <li>Motorrijtuigenbelasting inbegrepen</li>
                            <li>
                                Bandenvervanging, schadeherstel en glasreparatie
                                gedekt
                            </li>
                            <li>
                                Afleveringskosten en verwijderingsbijdrage
                                inclusief
                            </li>
                            <li>Onderhoud inbegrepen</li>
                            <li>Volle tank brandstof bij aflevering</li>
                        </ul>
                    </Container>
                </Col>
            </Row>
            <Container className="p-0 card-bags">
                <Row>
                    {cardData.map((items, index) => {
                        return (
                            <Col key={index} className="p-0 px-1">
                                <Card className="p-4 border-0">
                                    <CardTitle>
                                        <img src={items.img} alt="" />
                                    </CardTitle>
                                    <CardBody className="p-0">
                                        <p className="m-0 text-site-primary">
                                            {items.title}
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        );
                    })}
                </Row>
            </Container> */}
            </div>
        </>
    );
};

export default AllInclusive;
