import React, { useState } from "react";
import { Button, Col, Form, Row } from "reactstrap";
import { radioData_Page3 } from "./QuestionData";
import { useDispatch, useSelector } from "react-redux";
import CustomTableModal from "../CustomTableModal";
import {
    addLeadsData,
    updateLeadsData,
} from "../../store/actions/postLeadAction";

function QuestionsStep3({ isOpen, handleClose, onNext }) {
    // States
    const [selectedValue, setSelectedValue] = useState(null);
    const dispatch = useDispatch();

    const { leads } = useSelector((state) => state.postLead);

    // Functions
    const handleSelect = (value) => {
        radioData_Page3.map((item) => {
            if (leads.answers.includes(item.id)) {
                dispatch(
                    updateLeadsData({
                        valueToRemove: item.id,
                    })
                );
            }
        });

        const data = {
            name: "answers",
            value: value,
        };
        dispatch(addLeadsData(data));
        setSelectedValue(value);
        onNext({ question1: value });
    };
    return (
        <>
            <Form>
                {radioData_Page3.map((item) => (
                    <label
                        className={`custom-control bg_img_container_background rounded-pill py-3 my-3 cursor-pointer`}
                        key={item.id}
                        htmlFor={item.id}
                    >
                        <Row className="justify-content-between align-items-center px-3 px-md-4 py-2">
                            <Col>
                                <input
                                    type="radio"
                                    name="Radios"
                                    className="form-check-input"
                                    checked={
                                        selectedValue === item.id ||
                                        leads.answers.includes(item.id)
                                    }
                                    onClick={() => handleSelect(item.id)}
                                    value={item.id}
                                    id={item.id}
                                />
                                <label
                                    className="form-check-label ml-4 pt-1"
                                    htmlFor={item.id}
                                >
                                    {item.label}
                                </label>
                            </Col>
                            {item.Right_Side_Content && (
                                <Col className="text-right">
                                    <Button
                                        type="button"
                                        className="rounded-pill custom_rightContent px-md-4 py-2"
                                    >
                                        {item.Right_Side_Content}
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </label>
                ))}
            </Form>
            <CustomTableModal isOpen={isOpen} handleClose={handleClose} />
        </>
    );
}

export default QuestionsStep3;
