import WheelIcon from "../assets/img/wheel.svg";
import LedIcon from "../assets/img/led.svg";
import CarSignalIcon from "../assets/img/carSignal.svg";
import SignalIcon from "../assets/img/signal.svg";
import AirBagIcon from "../assets/img/airBag.svg";
import NavigateIcon from "../assets/img/navigate.svg";
import AirConditionerIcon from "../assets/img/airConditioner.svg";

export const standardEquipment = [
    {
        title: "17-inch lichtmetalen velgen",
        icon: WheelIcon,
    },
    {
        title: "LED-dagrijverlichting",
        icon: LedIcon,
    },
    {
        title: "Parkeersensoren, voor en achter",
        icon: CarSignalIcon,
    },
    {
        title: "Achteruitrijcamera",
        icon: SignalIcon,
    },
    {
        title: "5 airbags",
        icon: AirBagIcon,
    },
    {
        title: "12,3 inch Multimediascherm met navigatie",
        icon: NavigateIcon,
    },
    {
        title: "Airconditioning, volautomatisch met 2 zones",
        icon: AirConditionerIcon,
    },
];
