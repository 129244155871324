import { useDispatch, useSelector } from "react-redux";
import {
    addLeadsData,
    addLeadsUrlData,
    createPostLead,
} from "../store/actions/postLeadAction";
import Header from "../components/Header";
import AllInclusive from "../components/AllInclusive";
import HeroSection from "../components/HeroSection";
import PriceSection from "../components/PriceSection";
import SlickSlider from "../components/SlickSlideber";
import VersionAndFeatures from "../components/VersionAndFeatures";

import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { useParams } from "react-router";
import KonaStandardEquipment from "../components/KonaStandardEquipment";

const MainViewKona2 = () => {
    const dispatch = useDispatch();
    const [checkVersion, setCheckVersion] = useState();
    useEffect(() => {
        const currentUrl = window.location.pathname;
        const paramValue = currentUrl.substring(1);
        setCheckVersion(paramValue);
    }, []);
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const clickParam = queryParams.get("click");
        dispatch(
            addLeadsUrlData({
                name: "publisher_id",
                value: clickParam,
            })
        );
        const siteParam = queryParams.get("site");
        dispatch(
            addLeadsUrlData({
                name: "site_subid",
                value: siteParam,
            })
        );
        dispatch(
            addLeadsUrlData({
                name: "site_custom_url",
                value: " https://hyundai.topleaseauto.nl/kona-2",
            })
        );
        dispatch(
            addLeadsUrlData({
                name: "site_custom_name",
                value: "laagdempelig_hyundai_kona",
            })
        );
    }, []);
    useEffect(() => {
        const script1 = document.createElement("script");
        script1.async = true;
        script1.src =
            "https://www.googletagmanager.com/gtag/js?id=G-7HBBQ37F4F";
        document.head.appendChild(script1);
        const script2 = document.createElement("script");
        script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        
        gtag('config', 'G-7HBBQ37F4F');
            `;
        document.head.appendChild(script2);
        return () => {
            document.head.removeChild(script1);
            document.head.removeChild(script2);
        };
    }, []);
    return (
        <div className="position-relative">
            <HeroSection version={checkVersion} />
            <AllInclusive version={checkVersion} />
            <SlickSlider version={checkVersion} />
            <KonaStandardEquipment />
            {/* <PriceSection version={checkVersion} /> */}
            {/* <VersionAndFeatures version="2" /> */}
        </div>
    );
};

export default MainViewKona2;
