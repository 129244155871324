import { RepositoryFactory } from "../../repository/RepositoryFactory";

let Dealer = RepositoryFactory.get("dealers");

export const getLocation =
    (zip, onSuccess = (data) => {}, onLoading = (data) => {}) =>
    async (dispatch) => {
        try {
            onLoading(true);
            let data = await Dealer.getLocation({
                zip: zip,
            });
            onSuccess(data.data[0]);
        } catch (error) {
            onLoading(false);
            console.log("error", error);
        }
    };
export const getDealers =
    (payloadData, onSuccess = (data) => {}, onLoading = (data) => {}) =>
    async (dispatch) => {
        try {
            let data = await Dealer.getNearestDealers(payloadData);
            onSuccess(data);
            onLoading(false);
        } catch (error) {
            onLoading(false);
            console.log("error", error);
        }
    };
