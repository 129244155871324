import React, { Children, useRef, useState } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table,
} from "reactstrap";
import { versionList } from "../const/Object";
import VersionAndFeatures from "./VersionAndFeatures";
const CustomTableModal = ({ isOpen, handleClose }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={handleClose}
            className="bg-blue custom_rounded"
            size="xl"
        >
            <div className="modal_header_div d-flex justify-content-between align-items-center custom_rounded ">
                <ModalHeader className="modal_header modal_header_radius m-0 pl-4 pr-4 pt-4">
                    <h3 className="table_modal_title  p-1">
                        Uitvoeringen en hun functionaliteiten
                    </h3>
                </ModalHeader>
                <button
                    type="button"
                    className="close pr-4"
                    onClick={handleClose}
                >
                    <svg
                        className="mb-3"
                        width="26"
                        height="26"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M-0.00195312 18.5009C-0.00195312 8.44032 8.02609 0.255323 18.0286 0.00585839L18.4989 0C28.5595 0 36.7445 8.02804 36.9939 18.0306L36.9998 18.5009C36.9998 28.5614 28.9718 36.7464 18.9692 36.9959L18.4989 37.0018C8.43837 37.0018 0.25337 28.9737 0.00390527 18.9712L-0.00195312 18.5009Z"
                            fill="white"
                        />
                        <path
                            d="M26.2068 13.1079L23.8915 10.7925L18.4984 16.1858L13.1054 10.7925L10.79 13.1079L16.1834 18.5009L10.79 23.8939L13.1054 26.2093L18.4984 20.8159L23.8915 26.2093L26.2068 23.8939L20.8135 18.5009L26.2068 13.1079Z"
                            fill="#002E6B"
                        />
                    </svg>
                </button>
            </div>
            <ModalBody className="modal_body modal_body_radius">
                <VersionAndFeatures hideModalItem={true} />
            </ModalBody>
        </Modal>
    );
};

export default CustomTableModal;
