import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ScrollToTopButton from "../components/ScrollToTopButton";

const Main = (props) => {
    const [checkVersion, setCheckVersion] = useState();
    useEffect(() => {
        const currentUrl = window.location.pathname;
        const paramValue = currentUrl.substring(1);
        setCheckVersion(paramValue);
    }, []);
    return (
        <>
            <div className="d-flex flex-column " style={{ minHeight: "100vh" }}>
                <Header version={checkVersion} />
                <div style={{ flexGrow: 1 }}>{props.children}</div>
                <Footer version={checkVersion} />
            </div>
        </>
    );
};

export default Main;
