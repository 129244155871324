import React, { useState } from "react";
import PrivacyModal from "./PrivacyModal";

const Footer = ({ version }) => {
    console.log("version: ", version);
    const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
    const [isConditionModalOpen, setIsConditionModalOpen] = useState(false);
    const handleClosePrivacyModal = () => {
        setIsPrivacyModalOpen(false);
    };
    const handleCloseConditionModal = () => {
        setIsConditionModalOpen(false);
    };
    return (
        <>
            <div
                className={`${
                    version === "i10-2" || version === "kona-2"
                        ? "mt-0"
                        : version === "kona-1" || version === "konazakelijk"
                        ? "mt-1"
                        : "mt-5"
                }  mb-2`}
            >
                <hr
                    style={{ color: "E2E4FB" }}
                    className={`${
                        (version === "i10-2" || version === "kona-2") && "mt-0"
                    }`}
                />
                <div className="container p-0 w-100 d-flex justify-content-center align-items-center">
                    {version !== "konazakelijk" &&
                    version !== "konazakelijk/form" &&
                    version !== "konazakelijk/thankyou" ? (
                        <>
                            {" "}
                            <p className="custom_padding_footer-text text-secondary footer-text ">
                                Het Keurmerk Private Lease is toegekend aan
                                Hyundai Private lease. Het Keurmerk geeft je de
                                garantie dat je veilig, verantwoord en vertrouwd
                                een auto leaset. De voorwaarden zijn in
                                samenwerking met de Consumentenbond ontwikkeld.
                                <br />
                                <br />* Prijzen zijn op basis van een{" "}
                                {version === "i10-1" ||
                                version === "i10-2" ||
                                version === "i10-1/form" ||
                                version === "i10-2/form"
                                    ? "Hyundai i10 Comfort"
                                    : "Hyundai KONA Electric Comfort 48,4 kWh"}
                                . Genoemde vanafprijs van €
                                {version === "kona-2"
                                    ? "464"
                                    : version === "i10-1"
                                    ? "319"
                                    : "498"}{" "}
                                is op basis van Hyundai Private Lease, inclusief
                                BTW, op basis van 60 maanden looptijd en 10.000
                                kilometer per jaar.{" "}
                                {version !== "i10-1" && (
                                    <>
                                        Dit bedrag is inclusief SEPP subsidie
                                        van €61 per maand op de eerste 48
                                        maanden. Daarna is het maandbedrag vanaf
                                        €{version === "kona-2" ? "525" : "559"}{" "}
                                        per maand. Kijk voor meer informatie
                                        over de SEPP subsidie op{" "}
                                        {/* *Hyundai Private Lease prijzen zijn incl. BTW en
                        afhankelijk van de gekozen uitvoering, op basis van 72
                        maanden looptijd en 5.000 kilometer per jaar. Voor
                        voorwaarden van toekenning en beschikbaarheid kijk op{" "} */}
                                        <a
                                            href="https://www.rvo.nl"
                                            target="_blank"
                                        >
                                            www.rvo.nl
                                        </a>
                                    </>
                                )}
                                <br />
                                <br />
                                Hyundai Private Lease wordt aangeboden in
                                samenwerking met Arval. Arval is voorzien van
                                het Keurmerk Private Lease. Kijk voor de
                                voorwaarden op{" "}
                                <a href="https://Hyundai.nl" target="_blank">
                                    Hyundai.nl
                                </a>
                                .
                            </p>
                        </>
                    ) : (
                        (version === "konazakelijk/form" ||
                            version === "konazakelijk/thankyou") && (
                            <>
                                {" "}
                                <p className="custom_padding_footer-text text-secondary footer-text ">
                                    * Prijzen zijn op basis van een Hyundai KONA
                                    Electric Comfort 48,4 kWh. Genoemde
                                    vanafprijs van €449 is op basis van Hyundai
                                    Zakelijke Lease, exclusief BTW, op basis van
                                    60 maanden looptijd en 10.000 kilometer per
                                    jaar.
                                    <br />
                                    <br />
                                    Hyundai Zakelijke Lease wordt aangeboden in
                                    samenwerking met Arval. Kijk voor de
                                    voorwaarden op{" "}
                                    <a
                                        href="https://Hyundai.nl"
                                        target="_blank"
                                    >
                                        Hyundai.nl
                                    </a>
                                    .
                                </p>
                            </>
                        )
                    )}
                </div>
                <p className="text-center text-secondary footer-text pt-2 pb-2">
                    Bekijk de{" "}
                    <a
                        // target="_blank"
                        rel="noreferrer"
                        // href="https://leadgen.republish.nl/api/content/hyundai-privacy"
                        className="text-secondary cursor-pointer"
                        onClick={() => setIsPrivacyModalOpen(true)}
                        style={{ textDecoration: "underline" }}
                    >
                        Privacy
                    </a>{" "}
                    &{" "}
                    <a
                        rel="noreferrer"
                        // href=""
                        className="text-secondary cursor-pointer"
                        onClick={() => setIsConditionModalOpen(true)}
                        style={{ textDecoration: "underline" }}
                    >
                        voorwaarden
                    </a>{" "}
                    van deze actie.
                </p>
            </div>
            <PrivacyModal
                url={"https://leadgen.republish.nl/api/content/hyundai-privacy"}
                isOpen={isPrivacyModalOpen}
                handleClose={handleClosePrivacyModal}
                title={"Privacy"}
            />
            <PrivacyModal
                url={
                    "https://leadgen.republish.nl/api/content/hyundai-voorwaarden"
                }
                isOpen={isConditionModalOpen}
                handleClose={handleCloseConditionModal}
                title={"Voorwaarden"}
            />
        </>
    );
};

export default Footer;
