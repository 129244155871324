import React, { useEffect, useRef } from "react";
import { pricingList, versionList } from "../const/Object";
import { Button, Table } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ResetLeadsAnswers } from "../store/actions/postLeadAction";
import { useDispatch } from "react-redux";
const VersionAndFeatures = ({ version, hideModalItem }) => {
    const tableRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleScrollLeft = () => {
        if (tableRef.current) {
            tableRef.current.scrollLeft -= 50;
        }
    };

    const handleScrollRight = () => {
        if (tableRef.current) {
            tableRef.current.scrollLeft += 50;
        }
    };

    return (
        <>
            <div
                className={` d-flex justify-content-start align-items-center flex-column features flex-nowrap mt-5  pt-5  ${
                    version === "2" ? "mb-0" : "mb-5"
                }`}
            >
                {!hideModalItem && (
                    <div className="mt-5 w-100 d-flex justify-content-center">
                        <h1 className="title max-w mt-5">
                            Uitvoeringen en hun functionaliteiten
                        </h1>
                    </div>
                )}

                <div
                    className={`position-relative container d-flex justify-content-center ${
                        hideModalItem ? "mt-0" : "mt-5"
                    }  table-container`}
                >
                    <div className="w-100 overflow-auto" ref={tableRef}>
                        <Table className="overflow-hidden">
                            <thead>
                                <tr className="">
                                    <th className="bg-blue   feature-title ">
                                        <span className=" bg-blue-span d-flex justify-content-center align-items-center h-100 w-100 ">
                                            Functionaliteiten
                                        </span>
                                    </th>
                                    <th className="bg-light white-span ">
                                        <span className="white-span d-flex justify-content-center align-items-center h-100 w_responsive">
                                            i-Drive
                                        </span>
                                    </th>

                                    <th className="bg-blue">
                                        <div className="w-100 h-100 bg-blue d-flex align-items-center justify-content-center border-radius">
                                            <div className="d-flex flex-column justify-content-center align-items-center pb-3">
                                                <span className=" bg-blue-span bg-blue-span-text d-flex justify-content-center align-items-center h-100 w-100 pb-0">
                                                    Comfort
                                                </span>
                                                <span className="w-max custom_subtext_font">
                                                    vanaf €275 p/m
                                                </span>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="bg-light">
                                        {" "}
                                        <span className="white-span d-flex justify-content-center align-items-center h-100 w-100">
                                            Comfort Smart
                                        </span>
                                    </th>
                                    <th className="bg-light">
                                        <span className="white-span d-flex justify-content-center align-items-center h-100 w-100">
                                            Premium
                                        </span>{" "}
                                    </th>
                                    <th className="bg-light">
                                        <span className="white-span d-flex justify-content-center align-items-center h-100 w_responsive">
                                            N Line
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {versionList?.map((feature, index) => (
                                    <tr>
                                        <td
                                            className={`${
                                                index === 0
                                                    ? "first-row"
                                                    : "child-row"
                                            } p-0    ${
                                                index ===
                                                    versionList.length - 1 &&
                                                "lastrow"
                                            }`}
                                        >
                                            <span className="d-flex flex-column h-100 w-100 parent-span ">
                                                <span className="h-100 w-100 d-flex justify-content-start align-items-center pr-0  w-max border-bottom">
                                                    {feature.description}
                                                </span>
                                                {index !==
                                                    versionList.length - 1 && (
                                                    <span className="custom_table_line"></span>
                                                )}
                                            </span>
                                        </td>
                                        <td
                                            className={`${
                                                index === 0
                                                    ? "first-row"
                                                    : "child-row"
                                            } p-0 ${
                                                index ===
                                                    versionList.length - 1 &&
                                                "lastrow"
                                            }`}
                                        >
                                            <span className="h-100 w-100 parent-span">
                                                <span className="h-100 w-100 d-flex justify-content-center align-items-center">
                                                    {feature.iDrive ? (
                                                        <svg
                                                            width="25"
                                                            height="25"
                                                            viewBox="0 0 29 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M0.964661 13.9005C0.964661 6.38832 6.9592 0.276587 14.4281 0.090312L14.7793 0.0859375C22.2915 0.0859375 28.4032 6.08047 28.5895 13.5494L28.5939 13.9005C28.5939 21.4128 22.5993 27.5245 15.1304 27.7108L14.7793 27.7151C7.26705 27.7151 1.15531 21.7206 0.969035 14.2517L0.964661 13.9005Z"
                                                                fill="#002E6B"
                                                            />
                                                            <path
                                                                d="M21.5666 10.6033L19.8488 8.80078L12.9179 16.0668L10.1901 13.2044L8.46283 15.0042L12.9178 19.679L21.5666 10.6033Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="25"
                                                            height="25"
                                                            viewBox="0 0 29 29"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M0.964661 14.8043C0.964661 7.29213 6.9592 1.1804 14.4281 0.994121L14.7793 0.989746C22.2915 0.989746 28.4032 6.98428 28.5895 14.4532L28.5939 14.8043C28.5939 22.3166 22.5993 28.4283 15.1304 28.6146L14.7793 28.6189C7.26705 28.6189 1.15531 22.6244 0.969035 15.1555L0.964661 14.8043Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M20.5354 10.7768L18.8064 9.04785L14.7793 13.0752L10.7522 9.04785L9.02319 10.7768L13.0506 14.8039L9.02319 18.8311L10.7522 20.56L14.7793 16.5326L18.8064 20.56L20.5354 18.8311L16.508 14.8039L20.5354 10.7768Z"
                                                                fill="#F7795E"
                                                            />
                                                        </svg>
                                                    )}
                                                </span>
                                            </span>
                                        </td>
                                        <td
                                            className={`${
                                                index === 0
                                                    ? "first-row"
                                                    : "child-row"
                                            } p-0 bg-blue  ${
                                                index ===
                                                    versionList.length - 1 &&
                                                "lastrow"
                                            }`}
                                        >
                                            <span className="h-100 w-100 blue-span ">
                                                <span className="h-100 w-100 d-flex blue-span justify-content-center align-items-center">
                                                    {feature.comfort ? (
                                                        <svg
                                                            width="14"
                                                            height="12"
                                                            viewBox="0 0 14 12"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M13.1258 2.6033L11.4081 0.800781L4.47712 8.06679L1.74937 5.20438L0.0220947 7.00417L4.47705 11.679L13.1258 2.6033Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="22"
                                                            height="22"
                                                            viewBox="0 0 29 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M0.523926 13.9308C0.523926 6.4186 6.51846 0.306861 13.9874 0.120585L14.3385 0.116211C21.8507 0.116211 27.9625 6.11075 28.1488 13.5796L28.1531 13.9308C28.1531 21.443 22.1586 27.5548 14.6897 27.741L14.3385 27.7454C6.82631 27.7454 0.714576 21.7509 0.5283 14.282L0.523926 13.9308Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M20.0946 9.90377L18.3656 8.1748L14.3385 12.2022L10.3114 8.1748L8.5824 9.90377L12.6098 13.9309L8.5824 17.958L10.3114 19.687L14.3385 15.6596L18.3656 19.687L20.0946 17.958L16.0672 13.9309L20.0946 9.90377Z"
                                                                fill="#F7795E"
                                                            />
                                                        </svg>
                                                    )}
                                                </span>
                                            </span>
                                        </td>
                                        <td
                                            className={`${
                                                index === 0
                                                    ? "first-row"
                                                    : "child-row"
                                            } p-0 ${
                                                index ===
                                                    versionList.length - 1 &&
                                                "lastrow"
                                            }`}
                                        >
                                            <span className="h-100 w-100 parent-span">
                                                <span className="h-100 w-100 d-flex justify-content-center align-items-center">
                                                    {feature?.comfortSmart ? (
                                                        <svg
                                                            width="25"
                                                            height="25"
                                                            viewBox="0 0 29 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M0.964661 13.9005C0.964661 6.38832 6.9592 0.276587 14.4281 0.090312L14.7793 0.0859375C22.2915 0.0859375 28.4032 6.08047 28.5895 13.5494L28.5939 13.9005C28.5939 21.4128 22.5993 27.5245 15.1304 27.7108L14.7793 27.7151C7.26705 27.7151 1.15531 21.7206 0.969035 14.2517L0.964661 13.9005Z"
                                                                fill="#002E6B"
                                                            />
                                                            <path
                                                                d="M21.5666 10.6033L19.8488 8.80078L12.9179 16.0668L10.1901 13.2044L8.46283 15.0042L12.9178 19.679L21.5666 10.6033Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="25"
                                                            height="25"
                                                            viewBox="0 0 29 29"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M0.964661 14.8043C0.964661 7.29213 6.9592 1.1804 14.4281 0.994121L14.7793 0.989746C22.2915 0.989746 28.4032 6.98428 28.5895 14.4532L28.5939 14.8043C28.5939 22.3166 22.5993 28.4283 15.1304 28.6146L14.7793 28.6189C7.26705 28.6189 1.15531 22.6244 0.969035 15.1555L0.964661 14.8043Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M20.5354 10.7768L18.8064 9.04785L14.7793 13.0752L10.7522 9.04785L9.02319 10.7768L13.0506 14.8039L9.02319 18.8311L10.7522 20.56L14.7793 16.5326L18.8064 20.56L20.5354 18.8311L16.508 14.8039L20.5354 10.7768Z"
                                                                fill="#F7795E"
                                                            />
                                                        </svg>
                                                    )}
                                                </span>
                                            </span>
                                        </td>
                                        <td
                                            className={`${
                                                index === 0
                                                    ? "first-row"
                                                    : "child-row"
                                            } p-0 ${
                                                index ===
                                                    versionList.length - 1 &&
                                                "lastrow"
                                            }`}
                                        >
                                            <span className="h-100 w-100 parent-span ">
                                                <span className="h-100 w-100 d-flex justify-content-center align-items-center">
                                                    {feature?.premium ? (
                                                        <svg
                                                            width="25"
                                                            height="25"
                                                            viewBox="0 0 29 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M0.964661 13.9005C0.964661 6.38832 6.9592 0.276587 14.4281 0.090312L14.7793 0.0859375C22.2915 0.0859375 28.4032 6.08047 28.5895 13.5494L28.5939 13.9005C28.5939 21.4128 22.5993 27.5245 15.1304 27.7108L14.7793 27.7151C7.26705 27.7151 1.15531 21.7206 0.969035 14.2517L0.964661 13.9005Z"
                                                                fill="#002E6B"
                                                            />
                                                            <path
                                                                d="M21.5666 10.6033L19.8488 8.80078L12.9179 16.0668L10.1901 13.2044L8.46283 15.0042L12.9178 19.679L21.5666 10.6033Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="25"
                                                            height="25"
                                                            viewBox="0 0 29 29"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M0.964661 14.8043C0.964661 7.29213 6.9592 1.1804 14.4281 0.994121L14.7793 0.989746C22.2915 0.989746 28.4032 6.98428 28.5895 14.4532L28.5939 14.8043C28.5939 22.3166 22.5993 28.4283 15.1304 28.6146L14.7793 28.6189C7.26705 28.6189 1.15531 22.6244 0.969035 15.1555L0.964661 14.8043Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M20.5354 10.7768L18.8064 9.04785L14.7793 13.0752L10.7522 9.04785L9.02319 10.7768L13.0506 14.8039L9.02319 18.8311L10.7522 20.56L14.7793 16.5326L18.8064 20.56L20.5354 18.8311L16.508 14.8039L20.5354 10.7768Z"
                                                                fill="#F7795E"
                                                            />
                                                        </svg>
                                                    )}
                                                </span>
                                            </span>
                                        </td>
                                        <td
                                            className={`${
                                                index === 0
                                                    ? "first-row"
                                                    : "child-row"
                                            } p-0 ${
                                                index ===
                                                    versionList.length - 1 &&
                                                "lastrow"
                                            }`}
                                        >
                                            <span className="h-100 w-100 parent-span">
                                                <span className="h-100 w-100 d-flex justify-content-center align-items-center">
                                                    {feature?.premium ? (
                                                        <svg
                                                            width="25"
                                                            height="25"
                                                            viewBox="0 0 29 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M0.964661 13.9005C0.964661 6.38832 6.9592 0.276587 14.4281 0.090312L14.7793 0.0859375C22.2915 0.0859375 28.4032 6.08047 28.5895 13.5494L28.5939 13.9005C28.5939 21.4128 22.5993 27.5245 15.1304 27.7108L14.7793 27.7151C7.26705 27.7151 1.15531 21.7206 0.969035 14.2517L0.964661 13.9005Z"
                                                                fill="#002E6B"
                                                            />
                                                            <path
                                                                d="M21.5666 10.6033L19.8488 8.80078L12.9179 16.0668L10.1901 13.2044L8.46283 15.0042L12.9178 19.679L21.5666 10.6033Z"
                                                                fill="white"
                                                            />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="25"
                                                            height="25"
                                                            viewBox="0 0 29 29"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                clip-rule="evenodd"
                                                                d="M0.964661 14.8043C0.964661 7.29213 6.9592 1.1804 14.4281 0.994121L14.7793 0.989746C22.2915 0.989746 28.4032 6.98428 28.5895 14.4532L28.5939 14.8043C28.5939 22.3166 22.5993 28.4283 15.1304 28.6146L14.7793 28.6189C7.26705 28.6189 1.15531 22.6244 0.969035 15.1555L0.964661 14.8043Z"
                                                                fill="white"
                                                            />
                                                            <path
                                                                d="M20.5354 10.7768L18.8064 9.04785L14.7793 13.0752L10.7522 9.04785L9.02319 10.7768L13.0506 14.8039L9.02319 18.8311L10.7522 20.56L14.7793 16.5326L18.8064 20.56L20.5354 18.8311L16.508 14.8039L20.5354 10.7768Z"
                                                                fill="#F7795E"
                                                            />
                                                        </svg>
                                                    )}
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                    <div
                        className={`position-absolute  fixed-bottom ${
                            hideModalItem
                                ? "modal-scroll-buttons"
                                : "scroll-buttons"
                        } d-flex justify-content-start `}
                    >
                        <Button
                            className=" rounded-circle bg-blue  pt-3 pb-3 d-flex justify-content-center align-items-center mr-4"
                            onClick={() => handleScrollLeft()}
                        >
                            <span>
                                <svg
                                    width="10"
                                    height="12"
                                    viewBox="0 0 14 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.0336 1.00008L2.0672 7.03125L8.0336 13.0624"
                                        stroke="white"
                                        //stroke-opacity="0.54"
                                        stroke-width="2.81264"
                                    />
                                </svg>
                            </span>
                        </Button>
                        <Button
                            className="rounded-circle bg-blue pt-3 pb-3 d-flex justify-content-center align-items-center"
                            onClick={() => handleScrollRight()}
                        >
                            <span>
                                <svg
                                    width="10"
                                    height="12"
                                    viewBox="0 0 14 15"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M5.9664 1.00008L11.9328 7.03125L5.9664 13.0624"
                                        stroke="white"
                                        stroke-width="2.81264"
                                    />
                                </svg>
                            </span>
                        </Button>
                    </div>
                </div>
                {!hideModalItem && (
                    <div className="w-100 d-flex justify-content-center align-items-center mt-4 ">
                        <Button
                            className="bg-blue p-4  pl-4 pr-4 d-flex custom_button_margin"
                            onClick={() => {
                                dispatch(ResetLeadsAnswers());
                                version === "2"
                                    ? history.push("/i10-2/form")
                                    : history.push("/form");
                            }}
                        >
                            {" "}
                            <span className="h4 m-0 mr-2 font-weight-bolder">
                                {version === "2"
                                    ? "Bereken mijn leasetarief "
                                    : "Vraag offerte aan"}
                            </span>
                            <span className="pt-1 pl-2">
                                <svg
                                    width="22"
                                    height="15"
                                    viewBox="0 0 24 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M23.725 5.87313C23.7247 5.87282 23.7245 5.87245 23.7242 5.87214L18.8256 0.420521C18.4586 0.012122 17.865 0.0136418 17.4997 0.424086C17.1344 0.834477 17.1359 1.49826 17.5028 1.90672L20.7918 5.56685H0.9375C0.419719 5.56685 0 6.03621 0 6.61523C0 7.19426 0.419719 7.66362 0.9375 7.66362H20.7917L17.5029 11.3238C17.1359 11.7322 17.1345 12.396 17.4997 12.8064C17.865 13.2169 18.4587 13.2183 18.8256 12.8099L23.7242 7.35833C23.7245 7.35802 23.7247 7.35765 23.7251 7.35734C24.0922 6.94752 24.0911 6.28159 23.725 5.87313Z"
                                        fill="white"
                                    />
                                </svg>
                            </span>
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
};

export default VersionAndFeatures;
