import RepositoryDealer from "./RepositoryDealer";
import RepositoryLocation from "./RepositoryLocation";

const GET_DISTANCE = "get-distance";

export default {
    getNearestDealers(payload) {
        return RepositoryDealer.post(`${GET_DISTANCE}`, payload);
    },
    getLocation(payload) {
        return RepositoryLocation.get(
            `v2/suggest/nl/postalCode?postalCode=${payload.zip}&authKey=P6JTU52clKYjZca8`
        );
    },
};
