import React from "react";
import img from "../assets/img/overlay.png";
import img2 from "../assets/img/slider_img21.jpg";
// import konaImg1 from "../assets/img/kona.jpeg";
import konaImg1 from "../assets/img/kona--croped.jpeg";
import konaImg2 from "../assets/img/kona_inclusive.jpeg";

import { useRef } from "react";
import Slider from "react-slick";
import carImage from "../assets/img/slider_Img_11.jpg";
import { NavHashLink } from "react-router-hash-link";
const HeroSlider = ({ version }) => {
    const sliderRef = useRef(null);

    const handleNext = () => {
        sliderRef.current.slickNext();
    };

    const handlePrev = () => {
        sliderRef.current.slickPrev();
    };
    const settings = {
        arrows: true,
        dots: true,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
    };
    return (
        <div className="slider-container heroSection_carousel relative">
            <Slider {...settings} ref={sliderRef}>
                <img
                    src={
                        version === "kona-1" ||
                        version === "konazakelijk" ||
                        version === "kona-2"
                            ? konaImg1
                            : carImage
                    }
                />
                <img
                    src={
                        version === "kona-1" ||
                        version === "konazakelijk" ||
                        version === "kona-2"
                            ? konaImg2
                            : img2
                    }
                />
            </Slider>
            <p
                className={` d-md-block d-none ${
                    version === "kona-1" ||
                    version === "konazakelijk" ||
                    version === "kona-2"
                        ? "custom_block"
                        : "slider-container__top-text"
                }`}
            >
                {version === "kona-1"
                    ? "Vanaf €498 p/m*"
                    : version === "kona-2"
                    ? "Vanaf €464 p/m*"
                    : version === "konazakelijk"
                    ? "Vanaf €449 p/m*"
                    : version == "1"
                    ? "Tijdelijk voor maar €319 p/m"
                    : "Tijdelijk voor maar €319 p/m"}
            </p>
            <div className="custom-arrows">
                <i
                    className="fa-lg fa fa-chevron-left cursor-pointer text-white"
                    onClick={handlePrev}
                ></i>
                <i
                    className="fa-lg ml-5 fa fa-chevron-right cursor-pointer text-white"
                    onClick={handleNext}
                ></i>
            </div>
            <NavHashLink
                className="bottom-text m-0 text-decoration-none"
                smooth
                to="#inclusive-section"
            >
                {" "}
                Ontdek meer
            </NavHashLink>
        </div>
    );
};

export default HeroSlider;
