import QuestionsStep1 from "./QuestionsStep1";
import QuestionsStep2 from "./QuestionsStep2";
import QuestionsStep3 from "./QuestionsStep3";
import QuestionsStep4 from "./QuestionsStep4";

export const radioData_Page1 = [
    { id: 4777, value: "0 - 5.000 km", label: "0 - 5.000 km" },
    { id: 5054, value: "5.000 - 10.000 km", label: "5.000 - 10.000 km" },
    { id: 4780, value: "10.000 - 20.000 km", label: "10.000 - 20.000 km" },
    { id: 4783, value: "20.000 - 30.000 km", label: "20.000 - 30.000 km" },
    { id: 4786, value: "30.000 - 40.000 km", label: "30.000 - 40.000 km" },
    { id: 4789, value: "Meer dan 40.000 km", label: "Meer dan 40.000 km" },
    { id: 4792, value: "Weet ik nog niet", label: "Weet ik nog niet" },
];

export const radioData_Page2 = [
    { id: 4990, label: "3 jaar" },
    { id: 4993, label: "4 jaar" },
    { id: 4996, label: "5 jaar" },
    { id: 4999, label: "6 jaar" },
    { id: 5002, label: "Weet ik nog niet" },
];

export const radioData_Page3 = [
    { id: 4795, label: "Zo snel mogelijk" },
    { id: 4798, label: "Binnen 3 maanden" },
    { id: 4801, label: "Binnen 3 tot 6 maanden" },
    { id: 4804, label: "In overleg" },
    { id: 4807, label: "Weet ik nog niet" },
];

export const radioData_Page4 = [
    { id: 4810, boldLabel: "Ja", label: ", ik wil graag een proefrit maken" },
    { id: 4813, boldLabel: "Nee", label: ", ik hoef geen proefrit te maken" },
];

export const steps = [
    {
        pageNo: "Vraag 1 van 4",
        heading: "Hoeveel km verwacht jij te rijden per jaar?",
        component: <QuestionsStep1 />,
    },
    {
        pageNo: "Vraag 2 van 4",
        heading: "Gewenste looptijd leasecontract",
        component: <QuestionsStep2 />,
    },
    {
        pageNo: "Vraag 3 van 4",
        heading:
            "Wanneer zou jij mogelijk willen rijden in de nieuwe Hyundai KONA?",
        component: <QuestionsStep3 />,
    },
    {
        pageNo: "Vraag 4 van 4",
        heading:
            "Wil jij graag een proefrit maken bij een dealer in jouw regio?",
        component: <QuestionsStep4 />,
    },
];
