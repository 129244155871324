import { leadsData } from "../../const/LeadsDataObject";

const initState = {
    leads: leadsData,
    loading: false,
    leadDetails: {},
    errorResponse: null,
};

const postLeadReducer = (state = initState, action) => {
    switch (action.type) {
        case "SET_LEAD_LOADING":
            return {
                ...state,
                loading: action.payload,
            };
        case "ERROR_RESPONSE":
            return {
                ...state,
                errorResponse: action.payload,
            };
        case "SET_LEAD_DETAILS":
            return {
                ...state,
                leadDetails: action.payload,
            };
        case "RESET_ERROR_RESPONSE_NULL":
            return {
                ...state,
                errorResponse: null,
            };

        case "ADD_LEAD_DATA":
            const { name, value } = action.payload;
            let updatedLeads = { ...state.leads };

            if (name === "answers" && Array.isArray(updatedLeads.answers)) {
                updatedLeads.answers = [...updatedLeads.answers, value];
            } else {
                updatedLeads[name] = value;
            }

            return {
                ...state,
                leads: updatedLeads,
            };
        case "REMOVE_ANSWER_DATA":
            const { valueToRemove } = action.payload;
            const updatedLeadsRemove = { ...state.leads };

            if (Array.isArray(updatedLeadsRemove.answers)) {
                updatedLeadsRemove.answers = updatedLeadsRemove.answers.filter(
                    (item) => item !== valueToRemove
                );
            }

            return {
                ...state,
                leads: updatedLeadsRemove,
            };
        case "REMOVE_ANSWER_DATA":
            if (Array.isArray(updatedLeadsRemove.answers)) {
                updatedLeadsRemove.answers = updatedLeadsRemove.answers.filter(
                    (item) => item !== valueToRemove
                );
            }

            return {
                ...state,
                leads: updatedLeadsRemove,
            };
        case "RESET_LEAD":
            return {
                ...state,
                leads: {
                    ...state.leads,
                    answers: [],
                },
            };
        case "RESET_LEAD_KONA":
            return {
                ...state,
                leads: {
                    ...state.leads,
                    answers: [],
                },
            };

        default:
            return state; // Return the state directly in the default case
    }
};

export default postLeadReducer;
